export enum UserStatus {
  banned = 'banned',
  paused = 'paused',
  refused = 'refused',
  pending = 'pending_validation',
  clubMember = 'club_member',
  admin = 'admin',
  superAdmin = 'super-admin',
}

export function getStatusName(status: UserStatus) {
  
  switch (status) {
    case UserStatus.banned: return 'Banned';
    case UserStatus.paused: return 'Paused';
    case UserStatus.refused: return 'Refused';
    case UserStatus.pending: return 'Pending Admin Validation';
    case UserStatus.clubMember: return 'Active (a.k.a Club Member)';
    case UserStatus.admin: return 'Admin';
    case UserStatus.superAdmin: return 'Super admin (restricted)';
  }
}

export function getStatusColor(status: UserStatus) {
  
  switch (status) {
    case UserStatus.banned: return '#000';
    case UserStatus.paused: return 'purple';
    case UserStatus.refused: return 'red';
    case UserStatus.pending: return 'lime';
    case UserStatus.clubMember: return 'gold';
    case UserStatus.admin: return 'orange';
    case UserStatus.superAdmin: return 'volcano';
  }
}
