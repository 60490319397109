import {Button, Card, Col, Row, Statistic, Table, Tag} from "antd";
import {ArrowUpOutlined, ArrowDownOutlined, ArrowRightOutlined, EditFilled} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate} from "react-router-dom";
import Photographer from "../../chore/Photographers";
import SearchBar from "../../components/SearchInput";
import {HitArray} from "../../hooks/useAlgolia";
const { Column, ColumnGroup } = Table;
export default function PhotographersListPage() {
  
  const navigate = useNavigate()
  const [offset, setOffset] = useState<number>(0)
  const [photographers, setPhotographers] = useState<Photographer[] | HitArray<Photographer> | undefined>([])
  
  useEffect(() => {
    fetchPhotographers(100, 0).then()
  }, [offset])
  
  const fetchPhotographers = async (limit: number, skip: number = 0) => {
     let response = await Photographer.getAll(limit, skip);
     setPhotographers(response)
   }
  
  const handleResults = (results: HitArray<Photographer>) => {
    if(!results.length) {
      fetchPhotographers(100, 0).then()
    } else {
      setPhotographers(results)
    }
  }

  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Photographers List</h1>
          <Button type={"primary"} href={'./photographers/new'}>Add an influencer</Button>
      </Row>
  
      
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="New influencers last week"
                value={12}
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
              />
              <span>+41%</span>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="New influencers  last month"
                value={16}
                precision={2}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
              />
              <span>- 11% </span>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="New influencers last year"
                value={2}
                precision={2}
                valueStyle={{ color: '#7d7d7d' }}
                prefix={<ArrowRightOutlined />}
              />
              <span>0 %</span>
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{marginTop: 20}}>
  
        <SearchBar indice={'Photographers'} onResults={handleResults} placeholder={'Search for photographers'}/>
        {/* @ts-ignore */}
        <Table dataSource={photographers}>
          <Column title="Firstname" dataIndex="firstname" key="firstname" />
          <Column title="Lastname" dataIndex="lastname" key="lastname" />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title={'Actions'} render={(_, record: Influencer) => {
            return <>
              <Button type={"primary"} href={'./photographers/' + record.hash} shape={'round'}><EditFilled/> Edit</Button>
            </>
          }}/>
        </Table>
      </div>
      
    </>
  )
}
