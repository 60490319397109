import {
  Button,
  notification,
  Row,
  Table,
  Tag,
  Drawer,
  Space,
  Checkbox,
  Input,
  Form,
  Select,
  Divider,
  InputNumber
} from "antd";
import {FundOutlined, EyeOutlined, DatabaseOutlined, SaveOutlined, DeleteOutlined} from "@ant-design/icons";
import {FC, useEffect, useState} from "react";
import Link from "../../chore/Link";
import {format, parseISO} from "date-fns";



const {Column} = Table
const LinkPage = () => {
  
  
  const [links, setLinks] = useState<Link[]>([])
  const [selectedlink, setSelectedLink] = useState<Link | undefined>()
  const [dialogStatOpened, setDialogStatOpened] = useState<boolean>(false)
  
  
  const [form] = Form.useForm();
  
  
  
  useEffect(() => {
    if (selectedlink) {
      form.setFieldsValue({...selectedlink});
    }
  }, [selectedlink]);
  
  useEffect(() => {
    fetchLinks().then()
  }, []);
  
  
  
  const fetchLinks = async () => {
    const links = await Link.getAll()
    setLinks(links)
  }
  
  const onFinish = async (values: any) => {
    let l = Object.assign(new Link(), {...selectedlink}, {...values});
    await l.update()
    await fetchLinks()
    setSelectedLink(undefined);
    setDialogStatOpened(false)
  
  };
  
  const onChangeNetwork = (value: string) => {
    form.setFieldsValue({ socialNetwork: value });
    let l = Object.assign(new Link(), {...selectedlink}, {socialNetwork: value});
    setSelectedLink(l);
    
    
  };
  
  const DrawerStats: FC = () => {
    return (
      <>
        <Drawer
          title="Edit Link"
          placement={'right'}
          width={500}
          onClose={() => setDialogStatOpened(false)}
          visible={dialogStatOpened}
          extra={
            <Space>
              <Button onClick={() => setDialogStatOpened(false)}>Cancel</Button>
              <Button type="primary" onClick={() => form.submit()}>
                <SaveOutlined/> Save
              </Button>
            </Space>
          }
        >
          
          
          <Form form={form} onFinish={onFinish} layout={"vertical"}>
            
            <Form.Item name="socialNetwork" label="Social Network" rules={[{ required: true }]}>
              <Select
                placeholder="Select a social network"
                onChange={onChangeNetwork}
                allowClear
              >
                <Select.Option value="website">Website</Select.Option>
                <Select.Option value="instagram">Instagram</Select.Option>
                <Select.Option value="tiktok">Tiktok</Select.Option>
                <Select.Option value="twitter">X.com / Twitter</Select.Option>
              </Select>
            </Form.Item>
            
            <Form.Item name={'url'} label="URL" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            
            
            {selectedlink?.socialNetwork !== 'website' && <>

              <Form.Item name={"handle"} label="Handle" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              
              <Divider/>
              <h3>Metrics</h3>
              
              <Form.Item name={['data', 'followers']} label="Followers" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'following']} label="Followers" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>

            </>}
            
            
            
            {selectedlink?.socialNetwork === 'instagram' && <>
              <Form.Item name={['data', 'posts']} label="Post">
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'reels']} label="Reels" >
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'videos']} label="Videos" >
                <InputNumber />
              </Form.Item>
              
            </>}
            
            
            {selectedlink?.socialNetwork === 'tiktok' && <>

              <Form.Item name={['data', 'heart']} label="Hearts" >
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'videos']} label="Videos" >
                <InputNumber />
              </Form.Item>

            </>}
            
            {selectedlink?.socialNetwork === 'twitter' && <>

              <Form.Item name={['data', 'heart']} label="Hearts" >
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'medias']} label="Medias" >
                <InputNumber />
              </Form.Item>

              <Form.Item name={['data', 'tweets']} label="Tweets" >
                <InputNumber />
              </Form.Item>

            </>}
           
            
          </Form>
          
          <Button type="default" danger onClick={async() => {
            await selectedlink?.delete()
            await fetchLinks()
            setSelectedLink(undefined);
            setDialogStatOpened(false)
          }}>
            <DeleteOutlined/> Delete
          </Button>
          
        
        </Drawer>
      </>
    )
  }
  
  
  return (
    <>
      
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
      <h1 style={{fontSize: '2em'}}>Links</h1>
      </Row>
      
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        
        <Table dataSource={links} showHeader={true}>
          <Column title={'ID'} key={'id'} dataIndex={'id'}/>
          <Column title={'Handle'} key={'handle'} dataIndex={'handle'}/>
          <Column title={'URL'} key={'url'} dataIndex={'url'} render={(v) => <a href={v}>{v}</a> }/>
          <Column
            title={'Social network'}
            key={'type'}
            dataIndex={'socialNetwork'}
            render={(v) => <Tag>{v}</Tag> }
            filters={Array.from(new Set(links?.map(link => link.socialNetwork))).map((v) => {
              return {text: v as string, value: v as string}
            })}
            filterMode={'menu'}
            filterSearch={false}
            onFilter={(value, record: Link) => {
              return record.socialNetwork === value
              //return (record as Deal)!.brand!.slug.startsWith(value as string)
            }}
          />
          <Column title={'Errored'} key={'errored'} dataIndex={'errored'} render={(v) => <Tag color={v ? 'volcano' : 'lime'}>{v ? 'Yes' : 'No'}</Tag> }
                  filters={[{text: 'Erreur levée', value: true}, {text: 'Pas d\'erreur', value: false}]}
                  filterMode={'menu'}
                  filterSearch={false}
                  onFilter={(value, record: Link) => {
                    return record.errored === value
                    //return (record as Deal)!.brand!.slug.startsWith(value as string)
                  }}
          />
          <Column title={'Last scrapping attempt'} key={'lastScrappingDate'} dataIndex={'lastScrappingDate'} render={(d) => <span>{!!d && format(parseISO(d), 'dd MMMM yyyy HH:mm')}</span> }/>
          <Column title={'Action'} key={'hash'} dataIndex={'hash'} render={(hash, link: Link) =>
            <span style={{display: 'flex',gap:4, flexDirection: 'column'}}>
              <Button onClick={() => {
                setSelectedLink(link);
                setDialogStatOpened(true)
              }}><FundOutlined/> Views / Edit stats</Button>
              <Button type={"primary"}
                      href={!!link.influencerHash ? '/influencers/' + link.influencerHash : (!!link.brandHash ? '/brands/' + link.brandHash : '/photographers/' + link.photographerHash)}
              ><EyeOutlined/> See {!!link.influencerHash ? 'influencer' : (!!link.brandHash ? 'brand' : 'photographer')} profile</Button>
              <Button
              onClick={async () => {
                await link.retrieveData()
                notification.info({message: 'Update requested', description: 'This operation might take up to 2 minutes'})
              }}><DatabaseOutlined/> Reload stats</Button>
            </span> }/>
        </Table>

      </div>
      <DrawerStats/>
    </>
    
  )
  
  
}

export default LinkPage
