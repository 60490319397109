import {FC, useEffect, useRef, useState} from "react";
import Influencer from "../../chore/Influencer";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Row,
  Col,
  Spin,
  Checkbox, notification
} from "antd";
import Brand from "../../chore/Brand";

import {useForm} from "antd/es/form/Form";
import Deal from "../../chore/Deal";

import {HitArray} from "../../hooks/useAlgolia";

const { Option } = Select;


const DealDetailsSection: FC<{deal: Deal, onUpdate: (deal: Deal | null) => void}> = ({deal, onUpdate}) => {
  
  
  const [form] = useForm()
  const [brands, setBrands] = useState<HitArray<Brand>>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const stories = Form.useWatch('stories', form);
  const geolocation = Form.useWatch('geolocation', form);
  const instagramGuide = Form.useWatch('instagramGuide', form);
  
  const [loading, setLoading] = useState(false)
  const timeout = useRef<NodeJS.Timeout>();
  
  useEffect(() => {
    if(deal) {
      const values = JSON.parse(JSON.stringify(deal));
      setSelectedBrand(deal.brand)
      values.offer = deal.offer
      form.setFieldsValue(values)
    }
  }, [deal])
  
  const searchBrands = (query: string) => {
    
    if(timeout.current) clearTimeout(timeout.current)
    
    timeout.current = setTimeout(() => {
      Brand.getAll(100, 0, query).then((brands) => setBrands(brands));
    }, 400)
    
  }
  
  
  
  const onFinish = async (values: Record<keyof Deal, any>) => {
    setLoading(true)
    let d = Object.assign(new Deal(), deal, {...values});
    d.brandId = selectedBrand?.hash;
    d.offer = values.offer;
    d.storiesDetails = values.storiesDetails;
    d.tagsDetails = values.tagsDetails;
    d.postsDetails = values.postsDetails;
    if(d.hash) {
      await d.update();
      notification.success({message: 'Deal updated with success'});
      setLoading(false)
    } else {
      await d.create();
      notification.success({message: 'Deal created with success'});
      setLoading(false)
    }
    onUpdate(d)
  }
  
  const handleDelete = async () => {
    setLoading(true)
    const d = Object.assign(new Deal(), deal);
    await d.delete()
    notification.success({message: 'Deal deleted'})
    onUpdate(null)
    setLoading(false)
  }
  
  const handleArchive = async () => {
    setLoading(true)
    const d = Object.assign(new Deal(), deal);
    await d.archive()
    notification.success({message: deal.archived ? 'Deal archived' : 'Deal unarchived'})
    onUpdate(d)
    setLoading(false)
  }
  
  
  if(!deal) {
    return <Card style={{borderRadius: 12, margin: 12, display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Spin size={"large"}/>
    </Card>
  }
  
  
  return (
    <>
    
            <Form form={form} name={'deal-' + deal.hash} layout={'vertical'} onFinish={onFinish}>
              <Card style={{borderRadius: 12, margin: 12}}>
                <Input type={'search'} onChange={(e) => searchBrands(e.target.value)} disabled={loading}/>
                {brands.map(b => {
                  const isSelected = selectedBrand?.hash === b.hash
                  return (
                    //@ts-ignore
                    <Card style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }} onClick={() => setSelectedBrand(selectedBrand?.hash === b.hash ? undefined : b)  }>
                      <div>{isSelected ? '✅' : '⬜️'}</div>
                      <div>
                        <strong>{b.name}</strong>
                        <p>{b.description}</p>
                      </div>
                    </Card>
                    
                  )
                })}
                {!!selectedBrand && !brands.map(b => b.hash).includes(selectedBrand?.hash) &&
                  <Card style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }} onClick={() => setSelectedBrand(undefined )  }>
                    <div>✅</div>
                    <div>
                      <strong>{selectedBrand.name}</strong>
                      <p>{selectedBrand.description}</p>
                    </div>
                  </Card>
                }
              </Card>
              
              <Card style={{borderRadius: 12, margin: 12}}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item initialValue={deal.minFollower} label={'Min. followers'} name={'minFollower'}>
                    <InputNumber style={{width: '100%'}} min={0} step={100} disabled={loading}/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                <Form.Item initialValue={deal.maxFollower} label={'Max. followers'} name={'maxFollower'}>
                  <InputNumber style={{width: '100%'}} min={0} step={100} disabled={loading}/>
                </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item initialValue={deal.offer} label={'Offer'} name={'offer'}>
                    <Input.TextArea style={{width: '100%'}} rows={5} disabled={loading}/>
                  </Form.Item>
                </Col>
              </Row>
              </Card>
  
              <Card style={{borderRadius: 12, margin: 12}}>
              <h3>Stories</h3>
              <Divider/>
  
              <Row gutter={20}>
                <Col span={4}>
                  <Form.Item initialValue={deal.stories} label={'Required stories'} name={'stories'}>
                    <InputNumber style={{width: '100%'}} min={0} step={1} disabled={loading}/>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item initialValue={deal.maxFollower} label={'Detail about stories '} name={'storiesDetails'}>
                    <Input.TextArea style={{width: '100%'}} rows={1} disabled={stories === 0 || loading} placeholder={"2 stories during the experience, 2 stories 2 days later"} />
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item initialValue={deal.stories} label={'Tags to put stories'} name={'tags'}>
                    <Select mode={"tags"} style={{width: '100%'}} disabled={loading}/>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item initialValue={deal.tagsDetails} label={'Detail about Tgas '} name={'tagsDetails'}>
                    <Input style={{width: '100%'}} min={0} step={100} disabled={stories === 0 || loading} placeholder={"2 stories during the experience, 2 stories 2 days later"}/>
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={20}>
                <Col span={4}>
                  <Form.Item initialValue={deal.geolocation} label={'Geolocalization'} name={'geolocation'} valuePropName={"checked"}>
                    <Switch disabled={loading}/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item initialValue={deal.geolocationNumber} label={'Number of loc. stories'} name={'geolocationNumber'}>
                    <InputNumber style={{width: '100%'}} min={0} max={stories} step={1} disabled={!geolocation || loading}/>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item initialValue={deal.tagsDetails} label={'Detail about Geolocation'} name={'geolocationDetails'}>
                    <Input.TextArea  style={{width: '100%'}} rows={1} placeholder={"Don't tag #Trocadero, but #TourEiffel"}  disabled={!geolocation || loading}/>
                  </Form.Item>
                </Col>
              </Row>
              </Card>
  
              <Card style={{borderRadius: 12, margin: 12}}>
  
              <h3>Posts & IG Guide</h3>
              <Divider/>
  
              <Row gutter={20}>
                <Col span={4}>
                  <Form.Item initialValue={deal.posts} label={'Required Posts'} name={'posts'}>
                    <InputNumber disabled={loading} style={{width: '100%'}} min={0} step={1}/>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item initialValue={deal.postsDetails} label={'Detail about posts '} name={'postsDetails'}>
                    <Input.TextArea style={{width: '100%'}} rows={1} disabled={stories === 0 || loading} placeholder={"2 post during 1 day after, 2 post a week later"}/>
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={20}>
                <Col span={4}>
                  <Form.Item initialValue={deal.instagramGuide} label={'In your guide'} name={'instagramGuide'} valuePropName={'checked'}>
                    <Switch disabled={loading}/>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item initialValue={deal.instagramGuideDurationMonth} label={'Number of months in your guide'} name={'instagramGuideDurationMonth'}>
                    <InputNumber style={{width: '100%'}} min={0} step={1} disabled={!instagramGuide || loading}/>
                  </Form.Item>
                </Col>
              </Row>
              </Card>
  
              <Card style={{borderRadius: 12, margin: 12}}>
  
  
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item initialValue={deal.otherInformations} label={'Other information'} name={'otherInformations'}>
                    <Input.TextArea rows={5}/>
                  </Form.Item>
                </Col>
                
              </Row>
              </Card>
              
              <Card style={{borderRadius: 12, margin: 12}}>
                <Row gutter={12}>
                  <Col span={16}>
                    <Button type={"primary"} htmlType={"submit"} style={{width: '100%'}} disabled={!selectedBrand || loading}>Save Deal</Button>
                  </Col>
                  <Col span={4}>
                    <Button type={"primary"} style={{width: '100%'}} onClick={handleArchive} disabled={loading} >{deal.archived ? 'Unarchive' : 'Archive'}</Button>
                  </Col>
                  <Col span={4}>
                    <Button type={"primary"} danger style={{width: '100%'}} onClick={handleDelete}>Delete</Button>
                  </Col>
                  
                </Row>
                
              </Card>
            </Form>
      
    </>
  )
  
}

export default DealDetailsSection