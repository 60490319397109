import { Button, Card, Col, Row, Statistic, Table, Tag, TablePaginationConfig } from "antd";
import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, EditFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import User from "../../chore/User/User";
import useAlgolia, { HitArray } from "../../hooks/useAlgolia";
import { useNavigate } from "react-router-dom";
import { getStatusColor, getStatusName, UserStatus } from "../../chore/enums/UserStatus";

import { format, formatDistance, getUnixTime, isBefore, parseISO } from "date-fns";
import { Statistics } from "../../chore/interfaces/statistics";
import StatsCard from "../../components/StatsCard";
import APISearchBar from "../../components/APISearchInput";

const { Column, ColumnGroup } = Table;
const UsersListPage = () => {

  const [users, setUsers] = useState<User[] | HitArray<User> | undefined>([])
  const [stats, setStats] = useState<Statistics | undefined>()
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 20 })
  const [filters, setFilters] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate()
  const { searchWithPagination } = useAlgolia()

  useEffect(() => {
    fetchUsers((pagination.current ?? 1) - 1, pagination.pageSize ?? 20, query, filters).then()
  }, [pagination.current, pagination.pageSize, filters])

  useEffect(() => {
    fetchUsers(0, pagination.pageSize ?? 20, query, filters).then()
  }, [query])

  useEffect(() => {
    fetchStats().then()
  }, [])

  const fetchUsers = async (page: number, size: number, query?: string, filters?: string) => {
    const results = await searchWithPagination<User>('Users', query ? query : '', filters ? filters : '', { page, hitsPerPage: size })

    setUsers(results.hits)
    setPagination({
      ...pagination,
      total: results.nbHits,
    })
    return results.hits
  }

  const fetchStats = async () => {
    let response = await User.stats();
    setStats(response)

  }

  const handleResults = (results: HitArray<User>) => {
    if (!results.length) {
      fetchUsers(0, 0).then()
    } else {
      setUsers(results)
    }
  }

  const handleChange = (pagination: TablePaginationConfig, filters: any) => {

    const filterExpresstion = filters.status?.length ? filters.status.map((status: string) => `status:${status}`).join(' OR ') : '';
    setPagination({ current: pagination.current, pageSize: pagination.pageSize })
    setFilters(filterExpresstion);
  }


  return (
    <>
      <h1 style={{ fontSize: '2em' }}>Users List</h1>

      <StatsCard entity={'Users'} data={stats} />
      <div style={{ marginTop: 20 }}>
        <APISearchBar searchHandler={async (query: string | undefined) => {
          setQuery(query ?? '');
          return [];
          //return fetchUsers(0, 100, query, filters)
        }} placeholder={'Search for users'} />
        {/* @ts-ignore */}
        <Table dataSource={users}
          onChange={handleChange}
          pagination={{
            ...pagination,
            hideOnSinglePage: true,
          }} >
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="First Name" dataIndex="firstname" key="firstname" />
          <Column title="Last Name" dataIndex="lastname" key="lastname" />

          <Column
            title="Status"
            dataIndex="status"
            key="status"
            filters={Object.values(UserStatus).map((v) => {
              return { text: v as string, value: v as string }
            })}
            filterMode={'menu'}
            filterSearch={false}
            onFilter={(value, record) => {
              return (record as User).status === value as UserStatus
              //return (record as Deal)!.brand!.slug.startsWith(value as string)
            }}
            sorter={(a: User, b: User) => a.status.localeCompare(b.status)}
            render={tag => (
              <Tag color={getStatusColor(tag)} key={tag}>
                {getStatusName(tag)}
              </Tag>
            )}
          />
          <Column title="Created at" dataIndex="createdAt" key="createdAt"
            sorter={(a: User, b: User) => isBefore(parseISO(a.createdAt as string), parseISO(b.createdAt as string)) ? 1 : -1}
            render={
              date => date ? `${format(parseISO(date as string), 'dd/MM/yy')} (${formatDistance(
                parseISO(date),
                new Date(),
                { addSuffix: true }
              )})` : ''} />

          <Column
            title="Last updated"
            dataIndex="updatedAt"
            key="updatedAt"
            sorter={(a: User, b: User) => isBefore(parseISO(a.updatedAt as string), parseISO(b.updatedAt as string)) ? 1 : -1}
            render={
              date => date ? `${format(parseISO(date), 'dd/MM/yy')} (${formatDistance(
                parseISO(date),
                new Date(),
                { addSuffix: true }
              )})` : ''}
          />
          <Column title={'Actions'} dataIndex="hash" render={(hash: string) => {
            return <>
              <Button type={"primary"} href={'./users/' + hash}><EditFilled /> Editer</Button>
            </>
          }} />
        </Table>
      </div>





    </>
  )
}

export default UsersListPage
