import {Button, Card, Col, Input, Row, Statistic, Table, Tag} from "antd";
import {ArrowUpOutlined, ArrowDownOutlined, ArrowRightOutlined, EditFilled} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {Link, useNavigate} from "react-router-dom";
import Brand from "../../chore/Brand";
import SearchBar from "../../components/SearchInput";
import {HitArray} from "../../hooks/useAlgolia";
import {BrandType} from "../../chore/enums/BrandType";
import StatsCard from "../../components/StatsCard";
import {Statistics} from "../../chore/interfaces/statistics";
import {format, formatDistance, getUnixTime, isBefore, parseISO} from "date-fns";
import APISearchBar from "../../components/APISearchInput";
import {requestStatusName} from "../../chore/enums/RequestStatus";
import Deal from "../../chore/Deal";

const { Column, ColumnGroup } = Table;
const BrandsListPage = () => {
  
  const navigate = useNavigate()
  const [offset, setOffset] = useState<number>(0)
  const [brands, setBrands] = useState<Brand[] | HitArray<Brand> | undefined>([])
  const [stats, setStats] = useState<Statistics | undefined>()
  
  useEffect(() => {
    fetchBrand(offset, 100).then()
    fetchStats().then()
  }, [offset])
  
   const fetchBrand = async (skip: number, limit: number, query?: string) => {
     let response = await Brand.getAll(skip, limit, query);
     setBrands(response)
     return response;
   }
  const fetchStats = async () => {
    let response = await Brand.stats();
    setStats(response)
  }
   
   const handleResults = (results: HitArray<Brand>) => {
      if(!results.length) {
        fetchBrand(0, 100).then()
      } else {
        setBrands(results)
      }
   }
   
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Brands List</h1>
          <Button type={"primary"} href={'./brands/new'}>Add an brand</Button>
      </Row>
  
      <StatsCard entity={'Brands'} data={stats}/>
      <div style={{marginTop: 20}}>
        <APISearchBar searchHandler={(query?) => {
          return fetchBrand(0, 100, query)
        }} placeholder={'Search for Brands'}/>
        {/*@ts-ignore*/}
        <Table dataSource={brands}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="Name" dataIndex="name" key="name" render={(data: string, record: Brand) => {
            return <>
              <img src={record.profilePicture?.fullSizeUrl || 'https://dummyimage.com/50x50/c9c9c9/000000.png&text=No Profile'} width={50} height={50} style={{borderRadius: 25}}/> {data}
            </>
          }} />
          <Column title="Type" dataIndex="type" key="type"
                  filters={Object.values(BrandType).map((v) => {
                    return {text: v, value: v}
                  })}
                  //@ts-ignore
                  onFilter={(value: string, record: Brand) => {
                    return record.type?.includes(value as BrandType)
                  }}
                  render={(data: BrandType[]) => {
                    return data.map(t => <Tag>{t}</Tag>)
          }} />
          <Column
            title="Visibility"
            dataIndex="visible"
            key="visible"
            filters={[{text: 'Visible', value: true}, {text: 'Hidden', value: false}]}
            //@ts-ignore
            onFilter={(value: boolean, record: Brand) => {
              return record.visible === value
            }}
            render={(data: boolean, record: Brand) => {
            return data ? <Tag color={'green'} title={'Visible'}>Visible</Tag> : <Tag color={'red'} title={'Hidden'}>Hidden</Tag>;
          }} />
          <Column title="Created at" dataIndex="createdAt" key="createdAt"
                  sorter={(a: string, b: string) => getUnixTime(parseISO(a)) - getUnixTime(parseISO(b))}
                  render={
                    date => `${format(parseISO(date as string), 'dd/MM/yy')} (${formatDistance(
                      parseISO(date),
                      new Date(),
                      {addSuffix: true}
                    )})`} />
          <Column
            title="Last updated"
            dataIndex="updatedAt"
            key="updatedAt"
            sorter={(a: string, b: string) => isBefore(parseISO(a), parseISO(b)) ? -1 : 1}
            render={
              date => `${format(parseISO(date as string), 'dd/MM/yy')} (${formatDistance(
                parseISO(date),
                new Date(),
                {addSuffix: true}
              )})`}
          />
          <Column title={'Actions'} render={(_, record: Influencer) => {
            return <>
              <Button type={"primary"} href={'./brands/' + record.hash} shape={'round'}><EditFilled/> Edit</Button>
            </>
          }}/>
        </Table>
      </div>
      
      
      
      
      
    </>
  )
}

export default BrandsListPage
