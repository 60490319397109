import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import Base from "./Base";

export default class ContactMessage extends Base {
  
  firstname?: string
  lastname?: string
  email?: string
  phoneNumber?: string
  message?: string

  
  static  async getAll(limit: number = 400, skip: number = 0): Promise<ContactMessage[]> {
    const {data} = await client.request<ServerResponseType<ContactMessage[]>>(true, {
      url: '/v2/contacts',
      params: {
        skip, limit
      }
    });
    return data.data
  }
  async delete(): Promise<void> {
    await client.request<ServerResponseType<undefined>>(true, {
      method: 'DELETE',
      url: '/v2/contacts/' + this.hash,
      
    });
  }
}
