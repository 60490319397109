import {Button, Card, Col, Input, Row, Statistic, Table, Tag} from "antd";
import {ArrowUpOutlined, ArrowDownOutlined, ArrowRightOutlined, EditFilled} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate, useParams} from "react-router-dom";
import Brand from "../../chore/Brand";
import SearchBar from "../../components/SearchInput";
import {HitArray} from "../../hooks/useAlgolia";
import Deal from "../../chore/Deal";
import Testimonial from "../../chore/Testimonial";
const { Column, ColumnGroup } = Table;
const TestimonialListPage = () => {
  
  const navigate = useNavigate()
  const [testimonials, setTestimonials] = useState<Testimonial[]>([])
  
  useEffect(() => {
    fetchTestimonials().then()
  }, [])
  
   const fetchTestimonials = async (limit: number = 400, skip: number = 0) => {
     let response = await Testimonial.getAll(limit, skip);
     setTestimonials(response)
   }
   
   
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Testimonials</h1>
          <Button type={"primary"} href={'./new'}>Add a testimonial</Button>
      </Row>
      
      <div style={{marginTop: 20}}>
        {/* @ts-ignore */}
        <Table dataSource={testimonials}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title={'Testimony'} dataIndex="testimonial" key={"testimonial"}/>
          <Column title={'Author'} dataIndex="Author" key={"author"}/>
          <Column title={'Actions'} render={(_, record: Deal) => {
            return <>
              <Button type={"primary"} href={'./testimonials/' + record.hash} shape={'round'}><EditFilled/> Edit</Button>
            </>
          }}/>
        </Table>
      </div>
      
      
      
      
      
    </>
  )
}

export default TestimonialListPage
