import {Alert, Button, Card, Empty, Row, Select} from "antd";
import {useEffect, useState} from "react";
import Picture, {PictureStatus} from "../../chore/Picture";
import PictureRenderer from "../../components/PictureRenderer";

const PicturesPage = () => {
  
  
  const [pictures, setPictures] = useState<Picture[]>([])
  const [queryParams, setQueryParams] = useState<{ limit: number, offset: number }>({ limit: 50, offset: 0 })
  const [status, setStatus] = useState<PictureStatus | undefined>(undefined)
  
  useEffect(() => {
    fetchPictures()
  }, [queryParams, status]);
  
  
  
  const fetchPictures = () => {
    Picture.getAll({...queryParams, status}).then((picts) => {
      setPictures([...pictures, ...picts])
    });
  }
  
  const changeStatus = (status: any) => {
    setPictures([]);
    setQueryParams({limit: 50, offset: 0});
    setStatus(status === 'all' ? undefined : status)
    
  }
  
  return (
    <>
      
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
      <h1 style={{fontSize: '2em'}}>Pictures</h1>
      </Row>
      <Alert showIcon type={'info'}  message={'To upload pictures, go to Influencers, Brands or Photographers profiles.'}/>
      <div>
        Select picture status you'd like to see :
        <Select style={{width: 200}} onChange={(v: PictureStatus | undefined) => changeStatus(v)}>
          <Select.Option value={undefined} key={'all'}>All Pictures</Select.Option>
          {Object.values(PictureStatus).map((ps) => <Select.Option value={ps} key={ps}>{Picture.getStatus(ps)}</Select.Option> )}
        </Select>
      </div>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
      
  
      {!pictures.length ? <Empty description={'No pictures'}/> : pictures.map(p => <PictureRenderer onUpdated={() => {}} picture={p} key={p.hash} showUserDetails={true}/>)}
      
      </div>
    </>
    
  )
  
  
}

export default PicturesPage
