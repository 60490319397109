import Base from "./Base";
import Link from "./Link";
import {CustomerType} from "./enums/CustomerType";
import User from "./User/User";
import Address from "./Address";
import Picture from "./Picture";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import {parseISO} from "date-fns";
import {InfluencerRelationship} from "./enums/InfluencerRelationship";

export enum TestimonialType {
  LargePicture = 'Large Picture',
  SmallPicture = 'Small Picture',
  Influencer = 'Influencer Portrait',
}

export default class Testimonial extends Base {
  
  /* Topology */
  testimonial: string = ""
  type: TestimonialType = TestimonialType.LargePicture
  author: string = ""
  authorSubtitle: string = ""
  visible: boolean = false
  profilePicture?: Picture;
  coverPicture?: Picture;
  
  async create() {
  
    //@ts-ignore
    let dataTestimonial: Record<keyof Influencer, any> = {...this}
    
    delete dataTestimonial.prefix
    delete dataTestimonial.hash
    delete dataTestimonial.entityType
    
    const {data} = await client.request<ServerResponseType<Testimonial>>(true, {
      url: '/v2/testimonials',
      method: "POST",
      data: dataTestimonial
    });
    
    this.id = data.data.id;
    this.hash = data.data.hash;
    
    return this
  }
  
  static  async getAll(limit: number = 400, skip: number = 0): Promise<Testimonial[]> {
    const {data} = await client.request<ServerResponseType<Testimonial[]>>(true, {
      url: '/v2/testimonials',
      params: {
        skip, limit
      }
    });
    return data.data
  }
  
  static async get(hash: string): Promise<Testimonial> {
    const {data} = await client.request<ServerResponseType<Testimonial>>(true, {
      url: '/v2/testimonials/' + hash,
    });
    const t = Object.assign(
      new Testimonial(),
      {...data.data},
      {
        createdAt: parseISO(data.data.createdAt.toString()),
        updatedAt: parseISO(data.data.updatedAt.toString()),
      });
    return t
  }
  
  async get() {
    const {data} = await client.request<ServerResponseType<Testimonial>>(true, {
      url: '/v2/testimonials/' + this.hash,
    });
    return Object.assign(
      new Testimonial(),
      {...data.data},
      {createdAt: parseISO(data.data.createdAt.toString()), updatedAt: parseISO(data.data.updatedAt.toString())})
  }
  
  
  async update() {
    
    const {data} = await client.request<ServerResponseType<Testimonial>>(true, {
      url: '/v2/testimonials/' + this.hash,
      method: "PUT",
      data: {
        ...this
      }
    });
    console.log('update', data)
    
    
    return Object.assign(new Testimonial(), data.data)
    
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<boolean>>(true, {
      url: '/v2/testimonials/' + this.hash,
      method: "DELETE",
    });
    
    return status === 204
  }
  
  
}