import {Input} from "antd";
import useAlgolia, {HitArray, Indice} from "../hooks/useAlgolia";
import {FC, useRef} from "react";
import Base from "../chore/Base";

interface ISearchBar<T> {
  indice: Indice,
  onResults: (values: HitArray<T>) => void
  placeholder: string
}

export default function SearchBar<ResponseType extends Base>({indice, onResults, placeholder}: ISearchBar<ResponseType>) {
  
  const {search} = useAlgolia()
  const timeout = useRef<NodeJS.Timeout>()
  
  
  const triggerSearch = async (query: string) => {
  
    if (query.length === 0) {
      onResults([])
    }
    if (query.length < 3) {
      return;
    }
    
    if(timeout.current) clearTimeout(timeout.current)
    
    timeout.current = setTimeout(() => {
      search<ResponseType>(indice, query).then(results => {
        onResults(results)
      })
    }, 400)
  }
  
  return (
    <Input type={"search"} size={"large"} style={{marginBottom: 12}} placeholder={placeholder} onChange={e => triggerSearch(e.target.value)}/>
  )
}

