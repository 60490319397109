import {Button, Card, Col, Divider, Empty, Form, Input, InputNumber, notification, Row, Select} from "antd";
import {FC, useEffect, useState} from "react";
import {format} from "date-fns";
import ProfileType from "../../chore/enums/ProfileType";
import Picture from "../../chore/Picture";
import Link from "../../chore/Link";
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import {SocialNetworkType} from "../../chore/enums/SocialNetworkType";
import * as React from "react";

const {Option} = Select

interface LinkFormProps {
  link: Link;
  onFinish: (v: any) => void;
  onFailedFinish: (v: any) => void
  onDelete: (link: Link) => void
}
const LinkForm  = ({link, onFailedFinish, onFinish, onDelete} : LinkFormProps) => {
  
  
  const [placeholder, setPlaceholder] = useState('');
  
  const getPlaceholder = (socialNetwork: string) => {
    let sample: string = ''
    
    switch (socialNetwork) {
      case SocialNetworkType.Facebook:
        sample = 'https://www.facebook.com/clement.sauvage';
        break;
      case SocialNetworkType.Instagram:
        sample = 'https://www.instagram.com/camillededampierre';
        
        break;
      case SocialNetworkType.Pinterest:
        sample = 'https://www.pinterest.fr/clementsauvage_';
        
        break;
      case SocialNetworkType.Tiktok:
        sample = 'https://www.tiktok.com/@clementsauvage_';
        
        break;
      case SocialNetworkType.SnapChat:
        sample = 'https://www.snapchat.com/add/S4phyre';
        
        break;
      case SocialNetworkType.Twitter:
        sample = 'https://www.twitter.com/clementsauvage';
        break;
      case SocialNetworkType.Youtube:
        sample = 'https://www.youtube.com/c/notabenemovies'
        break;
      case SocialNetworkType.Website:
        break;
    }
  
    setPlaceholder(sample)
  }
  
  const retrieveData = async () => {
    const res = await link.retrieveData()
    notification.success({
      message: 'Refresh request sent',
      description: 'Please allow up to 3 minutes and refresh your page',
    })
  }
  
  
  return (
    <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFailedFinish}>
      <Form.Item initialValue={link.socialNetwork}
                 hidden={true}
                 name="hash"
                 valuePropName={"value"}>
        <Input hidden={true} value={link.hash}/>
      </Form.Item>
      <Row gutter={16}>
        <Col span={1}>
          #{link.id}
        </Col>
        <Col span={3}>
          <Form.Item initialValue={link.socialNetwork}
                     name="socialNetwork" label="Type"
                     valuePropName={"value"}
                     rules={[{ required: true, message: 'Missing social network' }]}>
            <Select showSearch disabled={!!link.hash} onChange={(v: string) => getPlaceholder(v)}>
              {Object.values(SocialNetworkType).map(sn => <Option value={sn}>{sn}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item initialValue={link.url}
                     name="url" label="URL"
                     rules={[{ required: true, message: 'Missing social URL' }]}>
            <Input type={"url"} disabled={!!link.hash} placeholder={placeholder}/>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Handle" initialValue={link.handle} name="handle">
            <Input type={"handle"} disabled/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Metrics">
            Followers / Subscribers : <strong>{link.data?.followers || '🤷🏻‍♂️'}</strong> |
            Following  : <strong>{link.data?.following || '🤷🏻‍♂️'}</strong> |
            Reach  : <strong>{link.data?.story_reach || '🤷🏻‍♂️'}</strong>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Actions">
            
            {!!link.hash ? <Button type={"primary"} danger shape={"round"} onClick={() => onDelete(link)}  icon={<MinusCircleOutlined />}>
              Delete
            </Button> : <Button type={"primary"} shape={"round"} htmlType={"submit"}  icon={<PlusOutlined />}>
              Add
            </Button>}
            {!!link.hash && <Button type={"ghost"} shape={"round"} onClick={retrieveData}  icon={<ReloadOutlined />}>
            Reload</Button>}
          </Form.Item>
        
        </Col>
  
       
      
      </Row>
    
    </Form>
  )
}

const LinksCard: FC<{links: Link[] | undefined, entityType: ProfileType, entityId: string, name: string}> = ({links, entityType, entityId, name}) => {
  
  const [l, setLinks] = useState<Link[]>(links || [])
  
  useEffect(() => {
    fetchAll().then()
  }, [links])
  
  
  const handleSave = async (link: any) => {
    let l = Object.assign(new Link(), link);
    if (l.hash) {
      await handleUpdate(l)
    } else {
      await handleCreate(l)
    }
  }
  const handleCreate = async (link: Link) => {
    await link.create(entityType, entityId);
    fetchAll().then();
  }
  const handleUpdate = async (link: Link) => {
    await link.update();
    fetchAll().then();
  }
  
  const handleDelete = async (link: Link) => {
    const l = Object.assign(new Link(), link)
    await l.delete();
    fetchAll().then();
  }
  
  const addLink = () => {
    const links = [...l]
    links.push(new Link())
    setLinks(links);
  }
  
  const fetchAll = async () => {
    let links = await Link.getAll({entity: entityType, entityId});
    setLinks(links)
  }
  
  const onFinish = async (value: any) => {
    await handleSave(value)
  }
  
  const onFinishFail = async (e: any) => {
    console.log(e)
  }
  
  
  
  return (
    <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
      <h2 style={{fontSize: '1.5em'}}>{name}'s links</h2>
      <Divider/>
      {l.length ? l.map(link => <LinkForm key={link.hash} onFinish={handleSave} onDelete={handleDelete} onFailedFinish={onFinishFail} link={link}/>) : <div style={{flex: 1}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<strong>No links</strong>} /></div>}
      <Divider/>
      <Button type={"primary"} size={"large"} style={{borderRadius: 12}} onClick={addLink} block icon={<PlusCircleOutlined />}>
        Add link
      </Button>
      
    </Card>
  )
}

export default LinksCard
