import {Button, Card, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";
import InfluencerProfileSection from "../../components/profile/InfluencerProfileSection";
import AddressCard from "../../components/profile/AddressCard";
import ProfileType from "../../chore/enums/ProfileType";
import PictureCard from "../../components/profile/PicturesCard";
import LinksCard from "../../components/profile/LinksCard";
import {LinkOutlined} from "@ant-design/icons";


const InfluencersDetailPage = () => {
  
  const [influencer, setInfluencer] = useState<Influencer | undefined>()
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    
    if(id !== 'new') {
      fetchInfluencer(id).then()
    } else {
      const influ = new Influencer();
      setInfluencer(influ)
    }
    
  }, [])
  
  
  
  const fetchInfluencer = async (hash: string) => {
    let response = await Influencer.get(hash);
    console.log(response)
    setInfluencer(response)
  }
  
  if(!influencer) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{influencer.name}</h1>
      </Row>
  
      <EntityCard entity={influencer}/>
      <InfluencerProfileSection influencerId={influencer.hash} onUpdate={infl => navigate((infl ? '../influencers/' + infl.hash : '../influencers'), {replace: true})}/>
      {!!influencer.hash ? <>
        <AddressCard address={influencer.address} entityType={ProfileType.Influencer} entityId={influencer.hash}
                     name={influencer.name}/>
        <PictureCard entityType={ProfileType.Influencer} entityId={influencer.hash}
                     name={influencer.name}/>
        <LinksCard links={influencer.links} entityType={ProfileType.Influencer} entityId={influencer.hash}
                   name={influencer.name}/>
        <Card style={{borderRadius: 12, margin: 12}}>
          <h2 style={{fontSize: '1.5em'}}>Other links</h2>
          <Divider/>
          <div style={{display: 'flex', flexDirection: 'row', gap: 40}}>
            <Button size={"large"} style={{borderRadius: 12, backgroundColor: "lavender"}}
                    href={'/requests/?influencerId=' + influencer?.hash} block
                    icon={<LinkOutlined/>}>
              See {influencer.name} requests
            </Button>
            <Button size={"large"} style={{borderRadius: 12, backgroundColor: "blueviolet"}} href={'/users/' + influencer?.user?.hash}
                    block icon={<LinkOutlined/>}>
              See {influencer.name} user
            </Button>
          </div>
    
        </Card>
      </> : <div/>}
      
    </>
  )
}

export default InfluencersDetailPage