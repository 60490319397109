

export enum StorageKeys {
  API_TOKEN = 'BOOKR::API_TOKEN',
  INTERNAL_VERSION = 'BOOKR::INTERNAL_VERSION',
}

export default class StorageManager {
  // Internal version

  static get internalVersion(): string | null {
    return window.localStorage.getItem('BOOKR::INTERNAL_VERSION')
  }

  static setVersion(value: string) {
    return window.localStorage.setItem('BOOKR::INTERNAL_VERSION', value)
  }

  // API TOKEN
  static get apiToken(): string | null {
    return window.localStorage.getItem('BOOKR::API_TOKEN')
  }

  static setApiToken(value: string) {
    return window.localStorage.setItem('BOOKR::API_TOKEN', value)
  }
  
  // HELPERS
  static deleteItem(key: string) {
    window.localStorage.removeItem('BOOKR::API_TOKEN')
  }
  
  static hasItem(key: string) {
    return !!window.localStorage.getItem(key)
  }
  
}
