import {FC, useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Row,
  Col,
  Spin,
  Checkbox, message, Radio, Space, RadioChangeEvent
} from "antd";
import PictureUploadModal from "../modals/PictureUploadModal";
import PictureType from "../../chore/enums/PictureType";
import ProfileType from "../../chore/enums/ProfileType";
import {parseISO} from "date-fns";
import moment from "moment";
import AttachUserModal from "../modals/AttachUserModal";
import {InfluencerRelationship, relationName} from "../../chore/enums/InfluencerRelationship";
import Brand from "../../chore/Brand";
import {BrandType} from "../../chore/enums/BrandType";
import {useForm} from "antd/es/form/Form";
import Testimonial, {TestimonialType} from "../../chore/Testimonial";
import {useNavigate, useParams} from "react-router-dom";

const { Option } = Select;


const TestimonialProfileSection: FC<{testimonial: Testimonial, onUpdate: (testimonial: Testimonial | null) => void}> = ({testimonial}) => {
  
  const [pictureModalVisible, setPictureModalVisible] = useState(false)
  const [showCoverImage, setShowCoverImage] = useState(false);
  const [currentType, setCurrentType] = useState<'profile' | 'cover'>('profile');
  const [t, setT] = useState<Testimonial>(testimonial)
  const [form] = useForm();
  const navigate = useNavigate()
  const params = useParams<{id: string}>();
  useEffect(() => {
      if(params.id)
        fetch(params.id).then()
  }, [])
  
  
  useEffect(() => {
    if(t) {
      const values = JSON.parse(JSON.stringify(testimonial));
      form.setFieldsValue(values)
    }
  }, [t])
  
  const fetch = async (hash: string) => {
    if(hash !== 'new') {
      let testimonial = await Testimonial.get(hash);
      setT(testimonial)
      setShowCoverImage([TestimonialType.SmallPicture, TestimonialType.LargePicture].includes(testimonial.type))
    } else {
      const testimonial = new Testimonial();
      setT(testimonial)
    }
  }
  
  
  const onFinish = async (values: Record<keyof Testimonial, any>) => {
    let testimonial = Object.assign(new Testimonial(), t, {...values});
    if(testimonial.hash) {
      await testimonial.update().catch((e: any) => message.error('Error while updating brand'))
      setT(t)
      message.success('Testimonial updated with success')
    } else {
      await testimonial.create();
      setT(t)
      message.success('Testimonial created with success')
    }
    
    navigate(-1)
  }
  
  const handleDelete = async () => {
    await t?.delete()
    navigate(-1)
  }
  
  
  if(!testimonial) {
    return <Card style={{borderRadius: 12, margin: 12, display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Spin size={"large"}/>
    </Card>
  }
  
  
  return (
    <>
      <Card style={{borderRadius: 12, margin: 12}}>
        
        <h2 style={{fontSize: '1.5em'}}>{testimonial?.id ? 'Testimonial' + testimonial.id
          : 'Nouveau Testimonial'} </h2>
        <Divider/>
        <Row>
          <Col span={6}>
            <p>Circled Image :</p>
            <img style={{borderRadius: 50, height: 100, width: 100, objectFit: "fill", marginRight: 30}} width={200} height={200} onClick={() => {
              setCurrentType('profile')
              setPictureModalVisible(true)
            }} src={t?.profilePicture?.fullSizeUrl || 'https://dummyimage.com/200x200/c9c9c9/000000.png&text=No+Profile+Picture'}/>
            {showCoverImage && <><p>Cover Image :</p>
            <img style={{borderRadius: 5, height: 100, width: '100%', objectFit: "fill", marginRight: 30}} onClick={() => {
              setCurrentType('cover')
              setPictureModalVisible(true)
            }} src={t?.coverPicture?.fullSizeUrl || 'https://dummyimage.com/600x200/c9c9c9/000000.png&text=No+Cover+Picture'}/></>}
          </Col>
          <Col span={18}>
          
            <Form form={form} name={'testimonial-' + testimonial?.hash} layout={'vertical'} onFinish={onFinish} style={{flex: 1}}>
  
              <Form.Item
                name="type"
                label="Radio.Button"
                rules={[{ required: true, message: 'Please pick an type!' }]}
                initialValue={t.type}
              >
                <Radio.Group onChange={(e) => setShowCoverImage([TestimonialType.SmallPicture, TestimonialType.LargePicture].includes(e.target.value))}>
                  <Radio.Button value={TestimonialType.Influencer}>Influencer</Radio.Button>
                  <Radio.Button value={TestimonialType.SmallPicture}>Brand - Small picture</Radio.Button>
                  <Radio.Button value={TestimonialType.LargePicture}>Brand - Large picture</Radio.Button>
                </Radio.Group>
              </Form.Item>
  
              <Form.Item name={'testimonial'} label={'Testimonial'} initialValue={t.testimonial} style={{marginTop: 20}}>
                <Input.TextArea name={'testimonial'} placeholder={'I love Marie'} style={{width: '100%', marginBottom: 10}} value={t.testimonial}/>
              </Form.Item>
  
              <Form.Item name={'author'} label={'Public Name'} initialValue={t.testimonial} style={{marginTop: 20}}>
                <Input placeholder={'Author'} style={{width: '100%', marginBottom: 10}} value={t.author}/>
              </Form.Item>
  
              <Form.Item name={'authorSubtitle'} label={'Public Name'} initialValue={t.testimonial} style={{marginTop: 20}}>
                <Input placeholder={'Author Subtitle'} style={{width: '100%', marginBottom: 10}} value={t.authorSubtitle}/>
              </Form.Item>
              
              <Button htmlType={"submit"} type={'primary'} shape={'round'} style={{flex: 3, color: "white"}}>Save</Button>
              <Button onClick={handleDelete} type={'default'} style={{backgroundColor: 'red', color: "white"}} shape={'round'} disabled={!!t.hash}>Delete</Button>
          
            </Form>
          </Col>
        
        </Row>
        
      </Card>
      <PictureUploadModal
        multiple={false}
        isVisible={pictureModalVisible}
        pictureType={PictureType.Testimonial}
        name={`Testimonial ` + currentType + ` picture`}
        entityId={testimonial?.hash}
        entityType={ProfileType.Unknown}
        onClose={(picture) => {
          setPictureModalVisible(false)
          if(picture?.length) {
            const testimonial = Object.assign(new Testimonial(), t);
            switch (currentType) {
              case "cover":
                testimonial.coverPicture = picture[0];
                break;
              case "profile":
                testimonial.profilePicture = picture[0];
                break;
                
            }
            setT(testimonial)
          }
        }}/>
      
    </>
  )
  
}

export default TestimonialProfileSection