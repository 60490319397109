import {Button, Card, Divider, Empty, } from "antd";
import {FC, useEffect, useRef, useState} from "react";


import ProfileType from "../../chore/enums/ProfileType";
import Picture, {PictureStatus} from "../../chore/Picture";
import PictureType from "../../chore/enums/PictureType";
import PictureUploadModal from "../modals/PictureUploadModal";
import PictureRenderer from "../PictureRenderer";




const PictureCard: FC<{entityType: ProfileType, entityId: string, name: string}> = ({entityType, entityId, name}) => {
  
  const [pictureModalVisible, setPictureModalVisible] = useState(false)
  const [pic, setPictures] = useState<Picture[]>([])
  
  useEffect(() => {
    loadPictures().then()
  }, [])
  
  const loadPictures = async () => {
    let pictures = await Picture.getAll({entity: entityType, entityId: entityId});
    setPictures(pictures);
  }
  
  return (
    <>
    <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
      <h2 style={{fontSize: '1.5em'}}>{name}'s pictures</h2>
      <Divider/>
      <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
        {!pic || !pic?.length ? <div style={{flex: 1}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<strong>No pictures</strong>} /></div> : pic.map((picture) => <PictureRenderer key={picture.hash} picture={picture} onUpdated={() => {
        setPictures([])
        loadPictures().then()
      }}/>)}
      </div>
      {pic && pic?.length <= 8 && <Button type={"primary"} size={"large"} style={{borderRadius: 12}} onClick={() => setPictureModalVisible(true)}>Add a new picture</Button>}
    </Card>
      <PictureUploadModal isVisible={pictureModalVisible} pictureType={PictureType.Asset} name={name} entityId={entityId} entityType={entityType} onClose={() => {
        setPictures([])
        setPictureModalVisible(false)
        loadPictures().then()
      }}/>
    </>
  )
}

export default PictureCard