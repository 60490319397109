import {Button, Card, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";

import AddressCard from "../../components/profile/AddressCard";
import ProfileType from "../../chore/enums/ProfileType";
import PictureCard from "../../components/profile/PicturesCard";
import LinksCard from "../../components/profile/LinksCard";
import {LinkOutlined} from "@ant-design/icons";
import Brand from "../../chore/Brand";
import BrandProfileSection from "../../components/profile/BrandProfileSection";

import PaymentCard from "../../components/profile/PaymentSection";
import OpeningHoursSection from "../../components/profile/OpeningHoursSection";

const BrandDetailPage = () => {
  
  const [brand, setBrand] = useState<Brand>(new Brand())
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    fetchBrand(id).then()
  }, [])
  
  
  const fetchBrand = async (hash: string) => {
    if(hash !== 'new') {
      const brand = await Brand.get(hash)
      console.log(brand)
      setBrand(brand)
    } else {
      const brand = new Brand();
      setBrand(brand)
    }
  }
  
  if(!brand) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{brand.name}</h1>
      </Row>
  
      <EntityCard entity={brand} name={brand.name}/>
      <BrandProfileSection brandId={brand.hash} onUpdate={infl => navigate((infl ? '../brands/' + infl.hash : '../brands'), {replace: true})}/>
      
      {!!brand.hash ? <>
        <OpeningHoursSection brandData={brand} onUpdate={() => {}}/>
        <PaymentCard name={brand.name} entityId={brand.hash}/>
        <AddressCard address={brand.address} entityType={ProfileType.Brand} entityId={brand.hash}
                     name={brand.name}/>
        
        <Card style={{borderRadius: 12, margin: 12}}>
          <h2 style={{fontSize: '1.5em'}}>Deals</h2>
          <Divider/>
          <div style={{display: "flex"}}>
          {brand.deals?.map((deal) => {
           return <div style={{backgroundColor: "#e6e6e6", padding: 12, borderRadius: 12, margin: 12}}>
             Min followers: <strong>{deal.minFollower}</strong> | Max followers: <strong>{deal.maxFollower}</strong><br/>
             Stories : <strong>{deal.stories}</strong> (with localization: <strong>{deal.geolocation ? deal.geolocationNumber : 'None'}</strong>)<br/>
             Posts : <strong>{deal.posts || 'None'}</strong><br/>
             Offer : <strong>{deal.offer}</strong><br/>
             
             <Button type={"primary"} href={'../deals/' + deal.hash} style={{backgroundColor: "#ebd219", borderRadius: 12, marginTop: 12, border: 'none'}}> See more </Button>
           </div>
          })}
          </div>
          
        </Card>
        
        <PictureCard entityType={ProfileType.Brand} entityId={brand.hash}
                     name={brand.name}/>
        <LinksCard links={brand.links} entityType={ProfileType.Brand} entityId={brand.hash}
                   name={brand.name}/>
        <Card style={{borderRadius: 12, margin: 12}}>
          <h2 style={{fontSize: '1.5em'}}>Other links</h2>
          <Divider/>
          <div style={{display: 'flex', flexDirection: 'row', gap: 40}}>
            <Button size={"large"} style={{borderRadius: 12, backgroundColor: "lavender"}}
                    href={'/requests/?brandId=' + brand?.hash}
                    block
                    icon={<LinkOutlined/>}>
              See {brand.name} requests
            </Button>
            <Button size={"large"} style={{borderRadius: 12, backgroundColor: "blueviolet"}}
                    href={'/users/' + brand?.owner?.hash}
                    block
                    icon={<LinkOutlined/>}>
              See {brand.name} user
            </Button>
          </div>
    
        </Card>
      </> : <div/>}
      
    </>
  )
}

export default BrandDetailPage