import {Alert, Button, Card, Divider, Empty, message, Popconfirm, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";
import ProfileType from "../../chore/enums/ProfileType";
import PictureCard from "../../components/profile/PicturesCard";
import User from "../../chore/User/User";
import UserProfileSection from "../../components/profile/UserProfileSection";
import useAuth from "../../hooks/useAuth";
import {UserStatus} from "../../chore/enums/UserStatus";
import {format, parseISO} from "date-fns";

const UsersDetailPage = () => {

  const [user, setUser] = useState<User | undefined>()
  const {id} = useParams<{id: string}>();
  const {payload} = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if(!id) return

    if(id !== 'new') {
      fetchUser(id).then()
    } else {
      const user = new User();
      setUser(user)
    }

  }, [])

  const fetchUser = async (hash: string) => {
    let response = await User.get(hash);
    setUser(response)
  }

  if(!user) {
    return <Spin/>
  }


  return (
    <>

      {!user.canEdit(payload?.status) && <Alert
        style={{borderRadius: 12, margin: 12}}
        message="You are not allowed to edit this user"
        description={<>{user.firstname} has role of <strong>{user.status}</strong> and you're <strong>{payload?.status}</strong>, you cannot update this profile </>}
        type="error"
      /> }

      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{user.name}</h1>
      </Row>

      <EntityCard entity={user} name={user.firstname}/>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Customer last password connexion</h3>
        Last connexion date : <strong> {format(parseISO(user.lastConnexion as string), `dd/MM/yyyy 'at' HH:mm`)} </strong> | Platform: <strong> {user.lastConnexionPlatform} </strong>
      </Card>
      <UserProfileSection userId={id} onUpdate={() => {}} disabled={!user.canEdit(payload?.status)}/>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Customer connexion</h3>
        <div>
        Copy the link and open it in a <strong>Private navigation window</strong>
        <Button type={'primary'} size={"large"} style={{borderRadius: 8, marginTop: 12, marginRight: 12}} onClick={async () => {
            const res = await user?.getAdminLink();
            const link = process.env.REACT_APP_FRONT_URL + '/login-access?access_token=' + res;
            await navigator.clipboard.writeText(link);
            message.success('Link copied in clipboard')
        }}>Copy Admin Link</Button>
        </div>
      </Card>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Security</h3>
        <Divider/>
        <Popconfirm
          title="An email will be sent to the user"
          onConfirm={async () => {
            if(user) {
              await user.resetPassword(user.email!)
            }
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button type={'primary'} size={"large"} style={{borderRadius: 8, marginTop: 12, marginRight: 12}} onClick={() => {}}> Reset Password</Button>
        </Popconfirm>
        {user.canEdit(payload?.status) &&<Popconfirm
          title="Are you sure ? All pending request will be cancelled, the user will not be informed"
          onConfirm={async () => {
            if (user) {
              user.status = UserStatus.banned
              await user.update();
              await fetchUser(user.hash)
            }
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button size={"large"} style={{borderRadius: 8, marginTop: 12, marginRight: 12, backgroundColor: 'black', color: 'white', outline: "none"}} onClick={() => {}}> Ban account</Button>
        </Popconfirm>}
        {user.canEdit(payload?.status) && <Popconfirm
          title="Are you sure ? User data will be erased, influencer, brands and photographer linked will be invisible"
          onConfirm={async () => {
            await user?.delete()
            navigate(-1)
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type={'primary'} size={"large"} style={{borderRadius: 8, marginTop: 12, marginRight: 12}} onClick={() => {}}> Delete account</Button>
        </Popconfirm>}
      </Card>
      <PictureCard entityType={ProfileType.User} entityId={user.hash}
                   name={`${user.firstname}`}/>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Influencers managed</h3>
        <Divider/>
        {user.influencers ? user.influencers?.map((influencer) => {
          return (
            <Card style={{backgroundColor: '#dedede'}}>
              <strong>{influencer.firstname || 'No firstname'} {influencer.lastname || 'No lastname'}</strong> (Id : <code>{influencer.hash}</code>)<br/>
              <Button type={'primary'} style={{borderRadius: 8, marginTop: 12, marginRight: 12}} href={'../influencers/'+influencer.hash}> See profile</Button>
              <Button type={'primary'} style={{borderRadius: 8, marginTop: 12}} href={'../requests/?influencerId='+influencer.hash}> See Requests</Button>
            </Card>
          )
        }) : <Empty description={'No Brands managed'}/>}
      </Card>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Brands managed</h3>
        <Divider/>
        {user.brands ? user.brands?.map((brand) => {
          return (
            <Card style={{backgroundColor: '#dedede'}}>
              <strong>{brand.name}</strong> (Id : <code>{brand.hash}</code>)<br/>
              <Button type={'primary'} style={{borderRadius: 8, marginTop: 12, marginRight: 12}} href={'../brands/'+brand.hash}> See profile</Button>
              <Button type={'primary'} style={{borderRadius: 8, marginTop: 12}} href={'../requests/?brandId='+brand.hash}> See Request</Button>
            </Card>
          )
        }) : <Empty description={'No Brands managed'}/>}
      </Card>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Photographers managed</h3>
        <Divider/>
        {user.photographers ? user.photographers?.map((photographer) => {
          return (
            <Card style={{backgroundColor: '#dedede'}}>
              <strong>{photographer.firstname} {photographer.lastname}</strong> (Id : <code>{photographer.hash}</code>)<br/>
              <Button type={'primary'} style={{borderRadius: 8, marginTop: 12}} href={'../photographers/'+photographer.hash}> See profile</Button>
            </Card>
          )
        }): <Empty description={'No Photographers managed'}/>}
      </Card>
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Customers Devices</h3>
        <small>List of devices registered by {user.firstname} on the mobile App</small>
        <Divider/>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column'}}><p>Cette fonction n'a pas encore été validée, merci de contacter votre account manager: Clément S.</p>
          <img src={require('../../assets/team-coding.png')} width={250} height={250} alt={'Illustration'}/></div>
      </Card>
      
      <Card  style={{borderRadius: 12, margin: 12}}>
        <h3>Notifications</h3>
        <small>List of notifications sent to {user.firstname}</small>
        <Divider/>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column'}}>
          <p>Cette fonction n'a pas encore été validée, merci de contacter votre account manager: Clément S.</p>
          <img src={require('../../assets/team-coding.png')} width={250} height={250} alt={'Illustration'}/>
        </div>
      </Card>

    </>
  )
}

export default UsersDetailPage
