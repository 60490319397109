import React from 'react';
import {Alert, Layout, Menu, Spin} from 'antd';
import { UserOutlined, DashboardOutlined, CodeOutlined, CalendarOutlined, PictureOutlined, CameraOutlined, CoffeeOutlined, TeamOutlined, HeartOutlined, MailOutlined, LinkOutlined } from '@ant-design/icons';
import {Outlet, Link, useNavigate, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {Navigate} from 'react-router-dom'

const { Header, Content, Footer, Sider } = Layout;
const  { Item } = Menu

const menuItems = [
  {
    key: 'Dashboard',
    icon: React.createElement(DashboardOutlined),
    label: 'Dashboard'
  },
  {
    key: 'users',
    icon: React.createElement(UserOutlined),
    label: 'Users'
  },
  {
    key: 'influencers',
    icon: React.createElement(TeamOutlined),
    label: 'Influencers'
  },
  {
    key: 'brands',
    icon: React.createElement(CoffeeOutlined),
    label: 'Brands & Places'
  },
  {
    key: 'photographers',
    icon: React.createElement(CameraOutlined),
    label: 'Photographers'
  },
  {
    key: 'deals',
    icon: React.createElement(UserOutlined),
    label: 'Deals'
  },
  {
    key: 'requests',
    icon: React.createElement(CalendarOutlined),
    label: 'Request'
  },
  {
    key: 'testimonials',
    icon: React.createElement(HeartOutlined),
    label: 'Testimonials'
  },
  {
    key: 'pictures',
    icon: React.createElement(PictureOutlined),
    label: 'Pictures'
  },
  {
    key: 'messages',
    icon: React.createElement(MailOutlined),
    label: 'Messages'
  },
  {
    key: 'social-links',
    icon: React.createElement(LinkOutlined),
    label: 'Liens'
  },
  {
    key: 'logs',
    icon: React.createElement(CodeOutlined),
    label: 'Logs'
  },
]



export default () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const {authed} = useAuth();
  
  
  return (
    <>
    
    <Layout style={{flex: 1, width: '100vw', height: '100vh'}}>
      
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
      >
        <h1 style={{color: "white", margin: 12, fontSize: 24}}> Admin Bookr </h1>
        <Menu
          theme="dark"
          mode="inline"
          onClick={(item) => navigate(item.key)}
          defaultSelectedKeys={['4']}
          items={menuItems}
        />
      </Sider>
      <Layout>
        
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
        <Content style={{ margin: '24px 16px 0', overflow: 'scroll' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {authed === undefined ? <Spin size={"large"}/> : (
              authed ? <Outlet/> : <Navigate to={'/login?error=not_authenticated&from=' + location.pathname}/>
            )}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>© Bookr 2022 | Created by <strong>Bits'n Coffee</strong></Footer>
      </Layout>
    </Layout>
    </>
    )
  
  
}
