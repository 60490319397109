import {FC, useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Spin,
  Row,
  Col,
  message, Checkbox
} from "antd";
import PictureUploadModal from "../modals/PictureUploadModal";
import PictureType from "../../chore/enums/PictureType";
import ProfileType from "../../chore/enums/ProfileType";
import {parseISO} from "date-fns";
import moment from "moment";
import AttachUserModal from "../modals/AttachUserModal";
import {InfluencerRelationship, relationName} from "../../chore/enums/InfluencerRelationship";
import {useForm} from "antd/es/form/Form";
import Photographer from "../../chore/Photographers";
import User from "../../chore/User/User";
import {getStatusName, UserStatus} from "../../chore/enums/UserStatus";
import {UserGender} from "../../chore/enums/UserGender";

const { Option } = Select;


const UserProfileSection: FC<{userId: string | undefined, onUpdate: (user: User | null) => void, disabled: boolean}> = ({userId, disabled}) => {
  
  const [pictureModalVisible, setPictureModalVisible] = useState(false)
  const [user, setUser] = useState<User>(new User())
  
  
  const [form] = useForm()
  
  useEffect(() => {
    if(userId) {
      fetchUser(userId).then()
    } else {
      setUser(new User())
    }
  }, [userId])
  
  useEffect(() => {
    if(user) {
      const values = JSON.parse(JSON.stringify(user));
      form.setFieldsValue(values)
    }
  },[user])
  
  const fetchUser = async (hash: string) => {
    const user = await User.get(hash);
    setUser(user)
  }
  
  const onFinish = async (values: Record<keyof User, any>) => {
    const usr = Object.assign(new User(), user, {...values});
    await usr.update().catch(() => message.error('An error occurred while updating user'))
    message.success('User updated with success')
    setUser(usr)
  }
  
  
  if(!user) {
    return <Card style={{borderRadius: 12, margin: 12, display: "flex"}}>
      <Spin size={"large"}/>
    </Card>
  }
  
  return (
    <>
      <Card style={{borderRadius: 12, margin: 12}}>
        <h2 style={{fontSize: '1.5em'}}>{user.firstname} {user.lastname} profile details </h2>
        User details are PII and thus <strong>🔒 private content</strong>
        <Divider/>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <img style={{borderRadius: 100, height: 200, width: 200, objectFit: "fill", marginRight: 30}} width={200} height={200} onClick={() => setPictureModalVisible(true)} src={user.profilePicture?.fullSizeUrl || 'https://dummyimage.com/200x200/c9c9c9/000000.png&text=No+Profile+Picture'}/>
          <Form form={form} name={'influencer-' + user.hash} layout={'vertical'} onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name={'firstname'} label={'Firstname'} initialValue={user}>
                  <Input placeholder={'Firstname'} style={{width: '100%', marginBottom: 10}} value={user.name} disabled={disabled}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'lastname'} label={'Lastname'} initialValue={user.name}>
                  <Input placeholder={'Lastname'} style={{width: '100%', marginBottom: 10}} value={user.name} disabled={disabled}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name={'email'} label={'E-mail'} initialValue={user.name}>
              <Input placeholder={'E-mail'} style={{width: '100%', marginBottom: 10}} value={user.name} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={'phoneNumber'} label={'Phone Number'} initialValue={user.phoneNumber}>
              <Input placeholder={'Phone Number'} type={"tel"} autoComplete={'tel'} maxLength={14} value={user.phoneNumber} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={'forceSMS'} label={'Force SMS'} initialValue={user.forceSMS}>
              <Input placeholder={'Force SMS for this user (careful, each SMS cost 0.07 €'} type={"checkbox"} value={user.phoneNumber} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={'gender'} label={'Gender'} initialValue={user.gender}>
              <Select allowClear={true} placeholder="Gender" onChange={(v) => { console.log(v)}} disabled={disabled}>
                {Object.values(UserGender).map(v => <Option value={v}>{v}</Option>)}
              </Select>
            </Form.Item>
            
            
            <Divider/>
            <h3>Admin controls</h3>
  
            <Form.Item name={'status'} label={'Status'} initialValue={user.status}>
              <Select placeholder="Status" style={{width: '80%'}} onChange={(v) => { console.log(v)}} disabled={disabled}>
                {Object.values(UserStatus).map(v => <Option value={v} disabled={v === UserStatus.superAdmin}>{getStatusName(v)}</Option> )}
              </Select>
            </Form.Item>
            
            <Form.Item name={'hasConfirmedEmail'} label={'Has confirmed email'} initialValue={user.hasConfirmedEmail} valuePropName={"checked"}>
              <Checkbox disabled/> User has confirmed email
            </Form.Item>
  
            <Form.Item name={'accountValidated'} label={'Account validated'} initialValue={user.accountValidated} valuePropName={"checked"}>
              <Checkbox> User account is validated (can connect to the website) </Checkbox>
            </Form.Item>
            
            <Divider/>
            <Button disabled={disabled} size={"large"} htmlType={"submit"} style={{backgroundColor: "green", color: "white", width: '80%'}} shape={"round"}>Save</Button>
          </Form>
        
        </div>
        
      </Card>
      <PictureUploadModal isVisible={pictureModalVisible} pictureType={PictureType.ProfilePicture} name={user.firstname || ''} entityId={user.hash} entityType={ProfileType.User} onClose={() => setPictureModalVisible(false)}/>
      
    </>
  )
  
}

export default UserProfileSection
