export enum RequestStatus {
  Approved = 'approved',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Finished = 'finished',
  AwaitingApproval = 'awaiting_approval',
  AwaitingConfirmation = 'awaiting_confirmation',
  Confirmed = 'confirmed',
  RequestingChange = 'requesting_change',
  PastDue = 'past_due',
  Archived = 'archived',
}

export function requestStatusName(status: RequestStatus)  {
  switch (status) {
    case RequestStatus.Approved:
      return 'Approved';
    case RequestStatus.Declined:
      return 'Declined';
    case RequestStatus.Cancelled:
      return 'Cancelled';
    case RequestStatus.Finished:
      return 'Finished';
    case RequestStatus.AwaitingApproval:
      return 'Awaiting Approval';
    case RequestStatus.AwaitingConfirmation:
      return 'Awaiting Confirmation';
    case RequestStatus.Confirmed:
      return 'Confirmed';
    case RequestStatus.RequestingChange:
      return 'Change Requested';
    case RequestStatus.PastDue:
      return 'Past Due';
    case RequestStatus.Archived:
      return 'Archived';
  }
}

export function requestColor(status: RequestStatus)  {
  switch (status) {
    case RequestStatus.Approved:
      return 'purple';
    case RequestStatus.Declined:
      return 'volcano';
    case RequestStatus.Cancelled:
      return 'red';
    case RequestStatus.Finished:
      return 'gold';
    case RequestStatus.AwaitingApproval:
      return 'geekblue';
    case RequestStatus.AwaitingConfirmation:
      return 'lime';
    case RequestStatus.Confirmed:
      return 'green';
    case RequestStatus.RequestingChange:
      return 'orange';
    case RequestStatus.PastDue:
      return 'magenta';
    case RequestStatus.Archived:
      return '#3b3b3b';
  }
}
