
import {
  AutoComplete,
  Button,
  Card, Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
  Space,
  Switch
} from "antd";
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons'
import {FC, useEffect, useState} from "react";
import ProfileType from "../../chore/enums/ProfileType";
import Address from "../../chore/Address";
import {countries, Country} from "countries-list";
import {useForm} from "antd/es/form/Form";


const {Option} = Select

const AddressCard: FC<{address?: Address, entityType: ProfileType, entityId: string, name: string}> = ({address, entityType, entityId, name}) => {
  
  
  const [adr, setAddress] = useState(Object.assign(new Address(), {...address}));
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([])
  const [form] = useForm()
  
  useEffect(() => {
    if(adr) {
      form.setFieldsValue(adr)
    }
  }, [adr])
  
  
  const handleDelete = () => {
    
    adr.delete().then(() => {
      notification.success({
        message: `Address deleted`,
      });
    }).finally(() => setLoading(false))
  }
  
  const handleSave = async (address: Address) => {
    if(address.hash) {
      return address.update();
    } else {
      return address.post(entityType, entityId)
    }
  }
  
  const onFinish = (values: any) => {
    setLoading(true)
    const address = Object.assign(new Address(), {...adr}, values);
    setAddress(address)
    handleSave(address).then((address) => {
      notification.success({
        message: `Address saved with success`,
      });
      setAddress(address)
    }).catch((e) => {
      notification.error({
        message: `An error happened while saving the address`,
      });
    }).finally(() => setLoading(false))
  };
  
  const  _handleSearch = async (v: string) => {
    
    if(v.length > 5) {
      let res = await Address.searchOnMapboxAddress(v);
      setAddresses(res)
    }
    
  }
  
  
  return (
    <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
      <h2 style={{fontSize: '1.5em'}}>{name}'s address</h2>
      <Divider/>
      <Form form={form} name={'address_' + entityId} layout={'vertical'} autoComplete={'off'} size={"large"} onFinish={onFinish}>
        
        <Row style={{flexDirection: 'row', flex: 1}}>
          <Col span={24}>
            <Form.Item label={'Friendly Name'} name={"name"} initialValue={address?.name}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
  
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={24}>
            <Form.Item label={'Autocomplete (the value upon select is NOT important)'} name={"autocomplete"} initialValue={address?.formatted}>
              <AutoComplete onSearch={async (v) =>  await _handleSearch(v)} onSelect={(i: number) => setAddress(addresses[i])}>
                {addresses.map((a, index) => <AutoComplete.Option key={index.toFixed(0)}>{a.formatted}</AutoComplete.Option>)}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={3}>
            <Form.Item label={'Street number'} name={"streetNumber"} style={{width: '100%'}} rules={[{ required: true, message: 'Missing street number' }]} initialValue={address?.streetNumber}>
              <InputNumber/>
            </Form.Item>
          </Col>
          <Col span={21}>
            <Form.Item label={'Street name'} name={"street"} rules={[{ required: true, message: 'Missing street name' }]} initialValue={address?.street}>
              <Input autoComplete={"street-address"}/>
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={4}>
            <Form.Item label={'Postal Code'} name={"postalCode"} rules={[{ required: true, message: 'Missing zip code' }]} initialValue={address?.postalCode}>
              <Input autoComplete={"postal-code"}/>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={'City'} name={"locality"} rules={[{ required: true, message: 'Missing city' }]} initialValue={address?.locality}>
              <Input autoComplete={"address-level2"}/>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={'State'} name={"state"} rules={[{ required: false}]} initialValue={address?.state}>
              <Input autoComplete={"address-level1"}  />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={24}>
            <Form.Item label={'Country'} name={"country"} rules={[{ required: true, message: 'Missing country' }]} initialValue={address?.country}>
              <Select showSearch placeholder="Country">
                {Object.values(countries).map((country: Country) => {
                  return <Option value={country.name}>{country.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={24}>
            <Form.Item label={'Extra informations'} name={"extra"} initialValue={address?.extra}>
              <Input.TextArea rows={3}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{flexDirection: 'row', flex: 1}}>
          <Col span={24}>
            <Form.Item name={"isPublic"} valuePropName={"checked"} initialValue={address?.isPublic}>
              <Checkbox> This is a public address (i.e. not a place to receive goods) </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <Form.List name="publicTransport" initialValue={address?.publicTransport}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={18} style={{display: 'flex', flexDirection: 'row', alignItems: "baseline", justifyContent: "space-between"}}>
                  <Col span={4}>
                  <Form.Item
                    {...restField}
                    name={[name, 'line']}
                    rules={[{ required: true, message: 'Missing subway line' }]}
                  >
                    <Input placeholder="Line" style={{width: 150}} />
                  </Form.Item>
                  </Col>
                  <Col span={10}>
                  <Form.Item
                    {...restField}
                    name={[name, 'stop']}
                    rules={[{ required: true, message: 'Missing subway station' }]}
                  >
                    <Input placeholder="Station" />
                  </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'method']}
                      rules={[{ required: true, message: 'Missing method' }]}
                    >
                      <Select placeholder={'Transportation Method'}>
                        <Option value={"metro"}>🚇{" "}Subway</Option>
                        <Option value={"bus"}>🚏{" "}Bus</Option>
                        <Option value={"tramway"}>🚎{" "}Tramway</Option>
                        <Option value={"flying-carpet"}>🧞‍♂️{" "}Flying Carpet</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Button type="dashed" danger onClick={() => remove(name)} block icon={<MinusCircleOutlined />}>
                      Remove
                    </Button>
                    
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                  Add public transport
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider/>
        <Row gutter={16}>
          <Col span={address?.hash ? 18 : 24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" size={"large"} shape={"round"} style={{width: '100%'}} loading={loading}>
                Save / Update
              </Button>
            </Form.Item>
          </Col>
          {!!address?.hash && <Col span={6}>
            <Form.Item>
              <Button type="primary"  danger onClick={() => handleDelete()} size={"large"} shape={"round"} style={{width: '100%'}} loading={loading}>
                Delete
              </Button>
            </Form.Item>
          </Col>}
        </Row>
        
      </Form>
      
      
      
      
    </Card>
  )
}

export default AddressCard