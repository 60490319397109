import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tag
} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";
import Request, {RequestStep} from "../../chore/Request";
import {formatDuration, intervalToDuration, isBefore, isEqual, parseISO} from "date-fns";
import {format} from "date-fns-tz";
import {requestColor, requestStatusName} from "../../chore/enums/RequestStatus";
import {getStepColor, getStepName, RequestStepStatus} from "../../chore/enums/RequestStepStatus";
import ProfileType from "../../chore/enums/ProfileType";
import moment from "moment-timezone";


const RequestDetailPage = () => {
  
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [request, setRequest] = useState<Request>()
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    fetchRequest(id).then()
  }, [])
  
  
  const fetchRequest = async (hash: string) => {
    let request = new Request();
    if(hash !== 'new') {
      request.hash = hash
      request = await request.get()
    }
    setRequest(request)
  }
  
  if(!request) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{request.name}</h1>
      </Row>
  
      <EntityCard entity={request} name={'Request between ' + request.brand?.name  + ' and ' + request.influencer?.name}/>
      <Row gutter={16} style={{flex: 1, flexDirection: 'row', margin: 6}}>
        <Col span={12}>
          <Card style={{flex: 1, borderRadius: 12}}>
            <h2>Influencer</h2>
            <Divider/>
            <strong>{request.influencer.firstname} {request.influencer.lastname}</strong> <br/>
            Managed by : {request.influencer.user?.firstname} <br/>
            <em>{request.influencer.user?.email} {request.influencer.user?.phoneNumber} <br/></em>
            <Button type={"primary"} style={{borderRadius: 12, marginTop: 12}} href={'../influencers/' + request?.influencer.hash}>Open {request.influencer.name} profile</Button>
            
          </Card>
        </Col>
        <Col span={12} >
          <Card  style={{flex: 1, borderRadius: 12}}>
            <h2>Brand</h2>
            <Divider/>
            <strong>{request.brand.name}</strong> <br/>
            Managed by : {request.brand.owner?.firstname} {request.brand.owner?.lastname} <br/>
            <em>{request.brand.owner?.email} {request.brand.owner?.phoneNumber} <br/></em>
            <Button type={"primary"} style={{borderRadius: 12, marginTop: 12}} href={'../brands/' + request?.brand.hash}>Open {request.brand.name} profile</Button>

          </Card>
        </Col>
      </Row>
      {/* Request details */}
      <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
        <>
        <h2 style={{fontSize: '1.5em'}}>Request details</h2>
        <Divider/>
          Status : <Tag color={requestColor(request.status)} style={{borderRadius: 8 }}>{requestStatusName(request.status)}</Tag><br/>
          Number of guests : <strong>{request.seats}</strong><br/>
          Date: {
            request.date ?  <>
              <strong>{format(parseISO((request.steps[0].dateTz as string).replace('Z', '')), 'MMMM, do. yyyy HH:mm')}</strong>
              <em> in {formatDuration(intervalToDuration({start: new Date(), end: parseISO((request.steps[0].dateTz as string).replace('Z', ''))}), {})}</em>
            </> : 'Not yet fixed'
          }<br/><br/>
  
          <Popconfirm title={'Are you sure'} onConfirm={async () => {
            await request?.delete()
            navigate('../requests')
          }} cancelText={'No'}>
            <Button danger type={"primary"} style={{borderRadius: 8}}>
              Delete request
            </Button>
          </Popconfirm>
        </>
      </Card>
      {/* Request details */}
      <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
        <>
          <h2 style={{fontSize: '1.5em'}}>Request steps</h2>
          <Divider/>
          {request.steps.sort((s1, s2) => isBefore(parseISO(s1.createdAt as string), parseISO(s2.createdAt as string)) ? -1 : 1).map(step => {
            return <Card style={{backgroundColor: '#eeeeee', borderRadius: 12, marginBottom: 20, fontSize: 12}}><br/>
              <Tag color={getStepColor(step.status!)} style={{flex: 1, alignItems: 'center', justifyContent: "center"}}>{getStepName(step.status!)}</Tag><br/>
              Step hash : <strong>{step.hash}</strong><br/>
              Guests: <strong>{step.seats !== request?.seats ?  <><s style={{color: "red"}}>{request?.seats}</s> <span>{step.seats}</span> </> : step.seats}</strong><br/>
              
              Date: {request?.dateTz ?
                <strong>{
                  !isEqual(
                    parseISO((step.dateTz as string).replace('Z', '')),
                    parseISO((request.dateTz as string).replace('Z', ''))
                  ) && [RequestStepStatus.ChangeSeatsAndDate, RequestStepStatus.ChangeDate].includes(step.status!) ?
                    <>
                      <s style={{color: "red"}}>
                        {
                          format(
                            parseISO((request?.dateTz as string)
                              .replace('Z', '')), "MMMM, do. yyyy 'at' HH:mm")}
                      </s>
                      <i>
                        {format(parseISO((step?.dateTz as string)
                          .replace('Z', '')), "MMMM, do. yyyy 'at' HH:mm")}
                      </i>
                    </> :
                    <>
                      {
                        format(
                          parseISO((step.dateTz as string).replace('Z', '')),
                          "MMMM, do. yyyy 'at' HH:mm"
                        )
                      }
                    </>}
                </strong>
                
                
                
                : 'Unknown'}<br/>
              
              From : <Tag>{step.from}</Tag><br/>
              Message : <em>{step.message}</em>
              <Divider/>
              <small>
              Created at : {format(parseISO(step.createdAt as string), "MMMM, do. yyyy 'at' HH:mm")}<br/>
              Updated at : {format(parseISO(step.updatedAt as string), "MMMM, do. yyyy 'at' HH:mm")}
              </small>
              
            </Card>
          })}
          <Button type={"primary"} style={{borderRadius: 8}} onClick={() => setUpdateModalVisible(true)}>
            Update request
          </Button>
    
        </>
      </Card>
      <UpdateRequestModal
        requestId={request.hash}
        visible={updateModalVisible}
        title={'Update request between ' + request.brand?.name  + ' and ' + request.influencer?.name}
        onClose={(shouldUpdate) => {
          if(shouldUpdate) fetchRequest(id as string).then()
          setUpdateModalVisible(false)
        }}/>
      
    </>
  )
}

const UpdateRequestModal = ({visible, title, onClose, requestId}: {visible: boolean, title: string, onClose: (shallUpdate: boolean) => void, requestId: string}) => {
  
  const [isVisible, setVisible] = useState(visible)
  const [currentStep, setCurrentStep] = useState<RequestStep>();
  const [disabled, setDisabled] = useState(false);
  
  
  useEffect(() => {
    setCurrentStep(new RequestStep())
  }, [])
  
  useEffect(() => {
    setVisible(visible)
  }, [visible])
  
  useEffect(() => {
    if(!currentStep?.status) return
    setDisabled([RequestStepStatus.RequestAccepted, RequestStepStatus.RequestConfirmed, RequestStepStatus.RequestDeclined, RequestStepStatus.RequestEnded, RequestStepStatus.RequestCancelled].includes(currentStep.status));
  }, [currentStep])
  
  
  const updateRequest = async () => {
    let req = new Request();
    req.hash = requestId;
    if(currentStep) await req.update(currentStep)
    setCurrentStep(new RequestStep());
  }
  
  const updateRequestField = (k: keyof RequestStep, v: RequestStepStatus | Date | Text | ProfileType | number | string | null) => {
    let step = Object.assign(new RequestStep(), {...currentStep});
    switch (k) {
      case "status":
        step.status = v as RequestStepStatus;
        break;
      case "date":
        step.date = v as Date;
        break;
      case "seats":
        step.seats = v as number;
        break;
      case "message":
        step.message = v as string;
        break;
  
      case "from":
        step.from = v as ProfileType;
        break;
    }
    setCurrentStep(step);
  }
  
  return (
    <Modal
      title={title}
      visible={isVisible}
      onOk={async () => {
        await updateRequest()
        setVisible(false)
        onClose(true)
      }}
      onCancel={() => {
        setVisible(false)
        onClose(false)
      }}
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
        <div>
          Step :<br/>
          <Select defaultValue={currentStep?.status} style={{width: '100%'}} onChange={(v: RequestStepStatus) => {
            updateRequestField('status', v)
          }}>
            {Object.values(RequestStepStatus).map(value => <Select.Option key={value} disabled={[RequestStepStatus.ChangeSeats, RequestStepStatus.ChangeDate].includes(value)}
              value={value}>{getStepName(value)}</Select.Option>)}
          </Select>
        </div>
    
        <div>
          Date : <strong>(⚠️ bien cliquer sur le petit OK après modification)</strong><br/>
          <DatePicker
            disabled={disabled}
            minuteStep={15}
            value={currentStep?.date ? moment(currentStep?.date) : undefined}
            disabledDate={date => date.isBefore(moment())} style={{width: '100%'}}
            showTime={{ format: 'HH:mm' }}
            onOk={(date) =>
            {
              let dd = parseISO(format(date.toDate(), "yyyy-MM-dd'T'HH:mm'Z'"));
              updateRequestField('date', dd)
          }} format={"dddd DD MMMM [at] HH:mm"}/>
        </div>
        
        <div>
          Guests :<br/>
          <InputNumber
            disabled={disabled}
            min={1}
            max={10}
            defaultValue={currentStep?.seats}
            onChange={(value) => {
              updateRequestField('seats', value)
            }}
            style={{width: '100%'}}/>
        </div>
        <div>
          Message :<br/>
          <Input
            placeholder={'Message'}
            style={{width: '100%'}}
            defaultValue={currentStep?.message}
            onChange={event => {
              updateRequestField('message', event.target.value)
            }}/>
        </div>
        <div>
          On Behalf of :<br/>
          <Select style={{width: '100%'}} onChange={(value: ProfileType) => {
            updateRequestField('from', value)
          }}>
            <Select.Option value={ProfileType.Influencer}>Influencer</Select.Option>
            <Select.Option value={ProfileType.Brand}>Brand</Select.Option>
          </Select>
        </div>
      </div>
    
    </Modal>
  )
}

export default RequestDetailPage
