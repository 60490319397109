import Base from "./Base";
import Brand from "./Brand";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import {parseISO} from "date-fns";
import {RequestStatus} from "./enums/RequestStatus";
import ProfileType from "./enums/ProfileType";
import Influencer from "./Influencer";
import {RequestStepStatus} from "./enums/RequestStepStatus";
import {Statistics} from "./interfaces/statistics";

export class RequestStep extends Base {
  
  prefix = 'step';
  status?: RequestStepStatus;
  from: ProfileType = ProfileType.Influencer;
  seats?: number = 2;
  message: string = ''
  request: Request = new Request();
  date?: Date | string;
  dateTz?: Date | string;

}

export default class Request extends Base {
  
  name?: string;
  prefix: string = 'req';
  entityType: string = 'Request';
  status!: RequestStatus;
  date?: Date | string;
  dateTz?: Date | string;
  seats?: number | null;
  initiatedBy!: ProfileType;
  brand!: Brand;
  influencer!: Influencer;
  steps!: RequestStep[];
  message?: string;
  archived: boolean = false
  
  
  async create() {
    
    if(!this.seats) return
    
    //@ts-ignore
    let data: Record<keyof Request, any> = {...this}
    
    delete data.prefix
    delete data.entityType
    
    const response = await client.request<ServerResponseType<Request>>(true, {
      url: '/v2/requests',
      method: "POST",
      data: {
        date: this.date,
        seats: this.seats,
        brand: this.brand.hash,
        influencer: this.influencer.hash,
        message: this.message,
        from: this.initiatedBy,
      }
    });
    
    this.id = response.data.data.id;
    this.hash = response.data.data.hash;
    this.createdAt = response.data.data.createdAt;
    this.updatedAt = response.data.data.updatedAt;
    
    return this
  }
  
  static  async getAll(limit: number, skip: number, brandId?: string | null, influencerId?: string | null , startDate?: Date, endDate?: Date): Promise<Request[]> {
    const {data} = await client.request<ServerResponseType<Request[]>>(true, {
      url: '/v2/requests',
      params: {
        skip, limit, brandId, influencerId, startDate, endDate
      }
    });
    return data.data.map(r => {
      return Object.assign(
        new Request(),
        {...r},
        {
          createdAt: parseISO(r.createdAt.toString()),
          updatedAt: parseISO(r.updatedAt.toString()),
          entityType: 'Request',
          date: parseISO(r.date as string)
        })
    })
  }
  
  async get(): Promise<Request> {
    const {data} = await client.request<ServerResponseType<Request>>(true, {
      url: '/v2/requests/' + this.hash,
    });
    return Object.assign(
      new Request(),
      {...data.data},
      {
        createdAt: parseISO(data.data.createdAt.toString()),
        updatedAt: parseISO(data.data.updatedAt.toString()),
        entityType: 'Request',
        date: parseISO(data.data.date as string)
      })
  }
  
  static async stats(influencerId?: string | null, brandId?: string | null): Promise<Statistics> {
    const {data} = await client.request<ServerResponseType<Statistics>>(true, {
      url: '/v2/requests/stats',
      params: {
        influencer: influencerId,
        brand: brandId
      }
    });
    return data.data
  }
  
  async update(step: RequestStep) {
    
    const res = await client.request<ServerResponseType<Request>>(true, {
      url: '/v2/requests/' + this.hash,
      method: "PATCH",
      data: step,
    });
    
    this.hash = res.data.data.hash;
    this.id = res.data.data.id
    
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<boolean>>(true, {
      url: '/v2/requests/' + this.hash,
      method: "DELETE",
      data: {
        ...this
      }
    });
    
    return status === 204
  }
  
}
