import {Button, Card, Col, Input, Row, Statistic, Table, Tag} from "antd";
import {ArrowUpOutlined, ArrowDownOutlined, ArrowRightOutlined, EditFilled} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate, useParams} from "react-router-dom";
import Brand from "../../chore/Brand";
import SearchBar from "../../components/SearchInput";
import {HitArray} from "../../hooks/useAlgolia";
import Deal from "../../chore/Deal";
const { Column, ColumnGroup } = Table;
const DealsListPage = () => {
  
  const navigate = useNavigate()
  const {brandId} = useParams<{brandId?: string}>()
  const [offset, setOffset] = useState<number>(0)
  const [deals, setDeals] = useState<Deal[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  
  useEffect(() => {
    fetchDeals(400, offset).then()
  }, [offset])
  
   const fetchDeals = async (limit: number, skip: number) => {
     let response = await Deal.getAll(limit, skip, brandId);
     setDeals(response)
     const brandResponse = await Brand.getAll(400, 0)
     setBrands(brandResponse)
   }
   
   
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Deals List</h1>
          <Button type={"primary"} href={'./deals/new'}>Add an deal</Button>
      </Row>
  
      <div style={{marginTop: 20}}>
        {/* @ts-ignore */}
        <Table dataSource={deals}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title={'Brand'} dataIndex="brand"
                  filters={brands.map(brand => ({text: brand!.name, value: brand?.slug}))}
                  filterMode={'menu'}
                  filterSearch={true}
                  onFilter={(value, record) => {
                    return (record as Deal)!.brand!.slug.startsWith(value as string)
                  }}
                  render={(data) => {
            return <>
              {data.name}
            </>
          }}/>
          <Column title={'Min Followers'} dataIndex="minFollower" key={"minFollower"}/>
          <Column title={'Max Followers'} dataIndex="maxFollower" key={"maxFollower"}/>
          <Column title={'Actions'} render={(_, record: Deal) => {
            
            return <>
              <Button type={"primary"} href={'./deals/' + record.hash} shape={'round'}><EditFilled/> Edit</Button>
            </>
          }}/>
        </Table>
      </div>
      
      
      
      
      
    </>
  )
}

export default DealsListPage
