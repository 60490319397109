import {Input} from "antd";
import {FC, useRef} from "react";
import Base from "../chore/Base";
import {HitArray} from "../hooks/useAlgolia";

interface IAPISearchBar<Search> {
  searchHandler: (query?: string | undefined) => Promise<Array<Search>> | Promise<HitArray<Search>>,
  placeholder: string
}

export default function APISearchBar<Search extends Base>({searchHandler, placeholder}: IAPISearchBar<Search>) {
  
  
  const timeout = useRef<NodeJS.Timeout>()
  
  
  const triggerSearch = async (query: string) => {
    
    
    
    if(timeout.current) clearTimeout(timeout.current)
    
    
    
    timeout.current = setTimeout(() => {
  
      if (!query.length) {
        searchHandler(undefined)
      } else {
        searchHandler(query)
      }
      
      
    }, 400)
  }
  
  return (
    <Input type={"search"} size={"large"} style={{marginBottom: 12}} placeholder={placeholder} onChange={e => triggerSearch(e.target.value)}/>
  )
}

