
import {
  Button,
  Card, Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
  Space,
  Switch
} from "antd";
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons'
import {FC, useEffect, useState} from "react";
import ProfileType from "../../chore/enums/ProfileType";
import Address from "../../chore/Address";
import {countries, Country} from "countries-list";
import Brand from "../../chore/Brand";


const {Option, OptGroup} = Select

const PaymentCard: FC<{entityId: string, name: string}> = ({ entityId, name}) => {
  
  const usdOptions = [229,339,449,559];
  const eurOptions = [199,299,399,499];
  
  const [currency, setCurrency] = useState('eur')
  const [price, setPrice] = useState(eurOptions[0])
  const [loading, isLoading] = useState(false)
  
  useEffect(() => {
    setPrice((currency === 'eur' ? eurOptions : usdOptions)[0])
  }, [currency]);
  
  const sendInvoice = async () => {
    isLoading(true)
    const brand = new Brand();
    brand.hash = entityId;
    await brand.createInvoice(currency, price).catch(() => {
      message.error('An error occured while sending invoice')
      isLoading(false)
    })
    message.success('Invoice sent with success');
    isLoading(false)
  }
  
  return (
    <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
      <h2 style={{fontSize: '1.5em'}}>{name} payment links</h2>
      <Divider/>
      
      <Row gutter={16}>
        <Col span={6}>
          <Select style={{width: '100%'}} value={currency} onChange={v => setCurrency(v)}>
              <Option value={'eur'}>Euros (€)</Option>
              <Option value={'usd'}>US Dollars ($US)</Option>
          </Select>
        </Col>
        <Col span={12}>
          <Select style={{width: '100%'}} value={price} onChange={v => setPrice(v)}>
              {(currency === 'eur' ? eurOptions : usdOptions).map(value => <Option value={value}>{new Intl.NumberFormat('fr-Fr', { style: 'currency', currency }).format(value)} / One Shot</Option>)}
          </Select>
        </Col>
        <Col span={6}>
          <Button type={"primary"} loading={loading} onClick={sendInvoice}>Send billable invoice</Button>
        </Col>
      </Row>
      
    </Card>
  )
}

export default PaymentCard