import Base from "./Base";
import ProfileType from "./enums/ProfileType";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import axios from "axios";
import {IMapboxGeocodingResponse} from "./interfaces/MapboxTypes";

interface Transportation {
  line: number | string;
  stop: string;
  vehicle: 'bus' | 'metro' | 'flying-carpet';
}

interface LatLng {
  latitude: number;
  longitude: number;
}

export default class Address extends Base {
  
  /* Topology */
  prefix = 'addr';
  coordinates!: LatLng;
  isoCountry = '';
  country = '';
  state = '';
  locality = '';
  postalCode = '';
  street = '';
  streetNumber = '';
  extra = '';
  publicTransport?: Transportation[];
  isPublic: boolean = false
  formatted = '';
  /* Methods */
  
  static fromMapbox(a: Record<string, any>, isPublic: boolean = false): Address {
    
    const address = new Address()
    
    address.coordinates = {
      longitude: a.center[0],
      latitude: a.center[1],
    }
    address.name = a.place_name
    address.isoCountry = a.context.find((contxt: { id: string, text: string, short_code: string }) => contxt.id.startsWith('country.'))?.short_code
    address.country = a.context.find((contxt: { id: string, text: string, short_code: string }) => contxt.id.startsWith('country.'))?.text
    address.state = a.context.find((contxt: { id: string, text: string, short_code: string }) => contxt.id.startsWith('region.'))?.text
    address.locality = a.context.find((contxt: { id: string, text: string }) => contxt.id.startsWith('place.'))?.text
    address.postalCode = a.context.find((contxt: { id: string, text: string }) => contxt.id.startsWith('postcode.'))?.text
    address.street = a.id.startsWith('address.') ? a.text : a.properties.address
    address.streetNumber = a.id.startsWith('address.') ? a.address : undefined
    address.formatted = `${address.streetNumber} ${address.street}, ${address.postalCode} ${address.locality}`
    
    return address
  }
  
  async getAll(entity: ProfileType, entityId: string, limit: number, skip: number): Promise<Address> {
    const {data} = await client.request<ServerResponseType<Address>>(true, {
      url: '/v2/addresses',
      params: {entity, entityId, limit, skip}
    })
  
    return data.data
  }
  
  static searchOnMapboxAddress = async (query: string): Promise<Address[]> => {
    
    const mapboxURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json`
  
    const res = await axios.request<IMapboxGeocodingResponse>({
      method: 'GET',
      url: mapboxURL,
      params: {
        access_token: process.env.REACT_APP_MAPBOX_TOKEN,
        language: 'fr',
        country: [
          'FR',
          'BE',
          'IT',
          'ES',
          'DE',
          'US',
          'CA',
          'NL',
        ].join(','),
        types: ['address', 'poi'].join(','),
        fuzzyMatch: true,
      },
    });
    
    return res.data.features.map(a => Address.fromMapbox(a))
    
  }
  
  async get(): Promise<Address> {
    const {data} = await client.request<ServerResponseType<Address>>(true, {
      url: '/v2/addresses/' + this.hash,
    })
  
    return data.data
  }
  
  async post(entity: ProfileType, entityId: string): Promise<Address> {
    const {data} = await client.request<ServerResponseType<Address>>(true, {
      method: 'POST',
      url: '/v2/addresses',
      data: {
        address: {...this},
        entity,
        entityId,
      }
    })
    
    return data.data
  }
  
  async update(): Promise<Address> {
    const {data} = await client.request<ServerResponseType<Address>>(true, {
      method: 'PUT',
      url: '/v2/addresses/' + this.hash,
      data: {
        address: {...this},
      }
    })
    return data.data
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<Address>>(true, {
      method: 'DELETE',
      url: '/v2/addresses/' + this.hash,
      data: {
        ...this,
      }
    })
    return status === 204
  }
  
  
  
  
}