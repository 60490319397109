import Picture, {PictureStatus} from "../chore/Picture";
import {FC, useEffect, useRef, useState} from "react";
import {Button, Dropdown, InputNumber, Menu, message, Space, Tag, Card} from "antd";
import PictureType from "../chore/enums/PictureType";
import {DeleteOutlined, SelectOutlined, DownOutlined, SaveOutlined} from "@ant-design/icons";

const PictureRenderer: FC<{picture: Picture, onUpdated?: () => void, showUserDetails?: boolean }> = ({picture, onUpdated, showUserDetails = false}) => {
  
  const [saved, setSaved] = useState(true)
  const [options, setOptions] = useState<{label: string, key: string}[]>([])
  const [loading, setLoading] = useState(false)
  const [p, setP] = useState(Object.assign(new Picture(), picture));
  
  const handleOption = ({key}: any) => {
    setSaved(false)
    setP(Object.assign(new Picture(), p, {status: key}))
  }
  
  const updatePosition = (value: number | undefined | null) => {
    if(!!value) {
      setSaved(false)
      setP(Object.assign(new Picture(), p, {order: value}));
    }
  }
  
  const save = async () => {
    setLoading(true)
    await p.update();
    setSaved(true)
    if(onUpdated) {
      onUpdated()
    }
    setLoading(false)
  }
  
  const deleteOption = () => {
    setLoading(true)
    p.delete().then(() => {
      if(onUpdated) onUpdated()

    })
  }
  
  useEffect(() => {
    const status = Object.values(PictureStatus).map(v => v);
    status.splice(0, 1);
    const menuOptions = status.map(s => {
      
      const p = new Picture();
      p.status = s;
      return {label: p.getStatus(), key: s}

    })
    setOptions(menuOptions)
  }, [])
  
  const menu = (
    <Menu
      onClick={handleOption}
      items={options}
    />
  );
  
  
  return (
    <Card style={{width: 'fit-content', alignItems: "center", justifyContent: "center", display: "flex", marginTop: 20, marginRight: 20}}>
      {!saved && <Tag color={'orange'} style={{width: '100%', marginBottom: 10}}><SaveOutlined/> Not Saved</Tag>}
      <figure style={{width: '100%'}}>
        <img src={p.fullSizeUrl} width={220}/>
        <figcaption>{p.name?.substr(0, 30)}...</figcaption>
      </figure>
      <div style={{width: 'fit-content', alignItems: "center", justifyContent: "center", display: "flex", gap: 10}}>
        <Tag color={p.getTypeColor()} style={{borderRadius: 5}}>{p.getType()}</Tag>
        <Tag color={p.getStatusColor()} style={{borderRadius: 5}}>{p.getStatus()}</Tag>
      </div>
      <div style={{width: '100%', alignItems: "center", justifyContent: "center", display: "flex", gap: 10, marginTop: 10}}>
        <div>
          <small>Position: </small><br/>
          <InputNumber style={{flex: 1}} min={0} max={10} value={p.order} onChange={(value) => updatePosition(value)}/>
        </div>
        <div>
          <small>Visibility: </small><br/>
          <Dropdown overlay={menu} overlayStyle={{width: '20%'}}>
            <Button type={"ghost"} style={{marginRight: 12}} loading={loading}>
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
      <div style={{width: '100%', alignItems: "center", justifyContent: "center", display: "flex", gap: 10, marginTop: 10}}>
        <Button type={"primary"} loading={loading} onClick={save} style={{flex: 1}}>
          <SaveOutlined/> Save
        </Button>
        <Button danger type={"primary"} loading={loading} onClick={deleteOption}>
          <DeleteOutlined/>
        </Button>
      </div>
      {/*showUserDetails &&
        <div style={{width: '100%', alignItems: "center", justifyContent: "flex-start", display: "flex", gap: 10, marginTop: 10}}>
          {!!picture.influencer && <Button href={`./influencers/${picture.influencer.hash}`}> See {picture.influencer.name}'s influencer profile <SelectOutlined style={{transform: `rotate(${90}deg)`}}/></Button>}
          {!!picture.brand && <Button href={`./brands/${picture.brand.hash}`}> See {picture.brand.name}'s brand profile <SelectOutlined style={{transform: `rotate(${90}deg)`}}/></Button>}
          {!!picture.photographer && <Button href={`./photographers/${picture.photographer.hash}`}> See {picture.photographer.name}'s photographer profile <SelectOutlined style={{transform: `rotate(${90}deg)`}}/></Button>}
        </div>*/}
      
      
    </Card>)

}

export default PictureRenderer
