import {Button, Card, Col, Input, Row, Statistic, Table, Tag, Space, Modal} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ContactMessage from "../../chore/ContactMessage";
import {format, parseISO} from "date-fns";
const { Column, ColumnGroup } = Table;
const MessagesListPage = () => {
  
  
  const [messages, setMessages] = useState<ContactMessage[]>([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [message, setMessage] = useState<ContactMessage | undefined>();
  
  useEffect(() => {
    fetchMessages().then()
  }, [])
  
   const fetchMessages = async (limit: number = 400, skip: number = 0) => {
     let response = await ContactMessage.getAll(limit, skip);
     setMessages(response)
   }
   
   
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Messages from contact form</h1>
      </Row>
      
      <div style={{marginTop: 20}}>
        {/* @ts-ignore */}
        <Table dataSource={messages}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title={'From'} render={(_, record: Record<string, any>) => {
            return <>
              <>{record.firstname} {record.lastname}</>
            </>
          }}/>
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="Phone Number" dataIndex="phoneNumber" key="phoneNumber" />
          <Column title={'Date'} render={(_, record: Record<string, any>) => {
            return <>
              <>{format(parseISO(record.createdAt), `MMM do. yy 'at' HH:mm`)}</>
            </>
          }}/>
          <Column title={'Actions'} render={(_, record: ContactMessage) => {
            return (
              <Space>
                <Button type={'primary'} onClick={() => {
                  setMessage(record)
                  setModalOpened(true)
                }}>Read message</Button>
                
                <Button title={'Reply'} target={'_blank'} href={`https://mail.google.com/mail/?view=cm&fs=1&to=${record.email}&su=Your%20message%20to%20Bookr`}>Reply</Button>
                {/*<Button title={'Delete'} danger={true}  onClick={async () => {
                  await Object.assign(new ContactMessage(), record).delete()
                  await fetchMessages()
                }}>Delete</Button>*/}
              </Space>
            )
          }}/>
        </Table>
      </div>
      <Modal visible={modalOpened} title={`Message from ${message ? message.firstname : ''}`}  onOk={() => setModalOpened(false)} onCancel={() => setModalOpened(false)}>
        <p>{message?.message}</p>
        <p>Phone : {message?.phoneNumber}</p>
        <Button title={'Reply'}  target={'_blank'} href={`https://mail.google.com/mail/?view=cm&fs=1&to=${message?.email}&su=Your%20message%20to%20Bookr`}>Reply</Button>
      </Modal>
      
    </>
  )
}

export default MessagesListPage
