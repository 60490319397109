export enum RequestStepStatus {
  Initiated = 'request_initiated',
  ChangeDate = 'request_change_date_requested',
  ChangeSeats = 'request_change_seats_requested',
  ChangeSeatsAndDate = 'request_change_seats_and_dates_requested',
  RequestChange = 'request_change',
  RequestAccepted = 'request_accepted',
  RequestDeclined = 'request_declined',
  RequestConfirmed = 'request_confirmed',
  RequestCancelled = 'request_cancelled',
  RequestEnded = 'request_ended',
}


export function getStepName(step: RequestStepStatus): string {
  switch (step) {
    case RequestStepStatus.Initiated: return 'Request Initiated';
    case RequestStepStatus.ChangeDate: return 'Date update requested';
    case RequestStepStatus.ChangeSeats: return 'Guest update requested';
    case RequestStepStatus.RequestChange: return 'Change Requested';
    case RequestStepStatus.RequestAccepted: return 'Request Accepted';
    case RequestStepStatus.RequestDeclined: return 'Request Declined';
    case RequestStepStatus.RequestConfirmed: return 'Request Confirmed';
    case RequestStepStatus.RequestCancelled: return 'Request Cancelled';
    case RequestStepStatus.ChangeSeatsAndDate: return 'Date & Guest update requested';
    case RequestStepStatus.RequestEnded: return 'Request Ended';
  }
}

export function getStepColor(step: RequestStepStatus): string {
  switch (step) {
    case RequestStepStatus.Initiated: return 'cyan';
    case RequestStepStatus.ChangeDate: return 'orange';
    case RequestStepStatus.ChangeSeats: return 'orange';
    case RequestStepStatus.RequestChange: return 'orange';
    case RequestStepStatus.ChangeSeatsAndDate: return 'orange';
    case RequestStepStatus.RequestAccepted: return 'lime';
    case RequestStepStatus.RequestDeclined: return 'red';
    case RequestStepStatus.RequestConfirmed: return 'green';
    case RequestStepStatus.RequestCancelled: return 'red';
    case RequestStepStatus.RequestEnded: return '';
  }
}
