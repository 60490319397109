import {FC, useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {Button, Card, TimePicker, Divider, Form, Input, InputNumber, Switch, Select, Row, Col, message} from "antd";
import Moment from 'moment';
import Brand from "../../chore/Brand";

import {MinusCircleOutlined, PlusCircleOutlined, SaveOutlined} from "@ant-design/icons";
import moment from "moment";
import {OpeningHours, Period} from "../../chore/interfaces/opening-hours";

const { Option } = Select;


const BrandProfileSection: FC<{brandData: Brand, onUpdate: (brand: Brand | null) => void}> = ({brandData, onUpdate}) => {
  
  const [brand, setBrand] = useState<Brand>(Object.assign(new Brand(), brandData))
  
  useEffect(() => {
    setBrand(Object.assign(new Brand(), brandData))
  }, [brandData])
  
  
  const onFinish = async (values: { periods : { day_open: number, day_close: number, time_open: moment.Moment, time_close: moment.Moment }[]
}) => {
    
    
    
    const periods: Period[] = [];
    values.periods.forEach((time) => {
      const period: Period = {
        open: {
          day: time.day_open,
          time: time.time_open.format('HH:mm')
        },
        close: {
          day: time.day_close,
          time: time.time_close.format('HH:mm')
        }
      }
      periods.push(period)
    })
    
    const brand = Object.assign(new Brand(), brandData, {openingHours: {periods: periods}})
    await brand.update();
    message.success("Opening times updated with success")
    
  }
  //{ day_open: number, day_close: number, time_open: moment.Moment, time_close: moment.Moment }
  const deconstructOpenings = () => {
    
    if(brandData.openingHours && brandData.openingHours.periods) {
      return brandData.openingHours.periods.map((period) => {
        return {
          day_open: period.open.day,
          day_close: period.close.day,
          time_open: moment(period.open.time, 'HH:mm'),
          time_close: moment(period.close.time, 'HH:mm'),
        }
      });
    } else {
      return []
    }
    
  }
  
  
  return (
    <>
      <Card style={{borderRadius: 12, margin: 12}}>
        
        <h2 style={{fontSize: '1.5em'}}>{brand.name} opening hours</h2>
        <Divider/>
        
          <Form layout={"vertical"} onFinish={onFinish} name={'opening_' + brand.hash} autoComplete={'off'} size={"large"}>
            <Form.List name="periods" initialValue={deconstructOpenings()}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={16}>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'day_open']}
                         label={'Open Day'}
                          rules={[{ required: true, message: 'Missing Day' }]}
                        >
                          <Select>
                            <Option value={1}>Monday</Option>
                            <Option value={2}>Tuesday</Option>
                            <Option value={3}>Wednesday</Option>
                            <Option value={4}>Thursday</Option>
                            <Option value={5}>Friday</Option>
                            <Option value={6}>Saturday</Option>
                            <Option value={0}>Sunday</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'time_open']}
                          label={'Opening time'}
                          rules={[{ required: true, message: 'Missing Open Time' }]}
                        >
                          <TimePicker format={'HH:mm'} hourStep={1} minuteStep={15} onChange={v => console.log(v)} style={{width: '100%'}}/>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'day_close']}
                          label={'Close Day'}
                          rules={[{ required: true, message: 'Missing Day' }]}
                        >
                          <Select>
                            <Option value={1}>Monday</Option>
                            <Option value={2}>Tuesday</Option>
                            <Option value={3}>Wednesday</Option>
                            <Option value={4}>Thursday</Option>
                            <Option value={5}>Friday</Option>
                            <Option value={6}>Saturday</Option>
                            <Option value={0}>Sunday</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'time_close']}
                          label={'Opening time'}
                          rules={[{ required: true, message: 'Missing Open Time' }]}
                        >
                          <TimePicker format={'HH:mm'} hourStep={1} minuteStep={15} onChange={v => console.log(v)} style={{width: '100%'}}/>
                        </Form.Item>
                      </Col>
                      
                      <Col>
                        <Form.Item
                          {...restField}
                          label={'Remove'}
                          rules={[{ required: true, message: 'Missing Open Time' }]}
                        >
                          <Button type="dashed" danger onClick={() => remove(name)} block icon={<MinusCircleOutlined />}>
                            Remove
                          </Button>
                        </Form.Item>
                       
                      </Col>
                      
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                      Add time slot
                    </Button>
                  </Form.Item>
                </>
              )}
    
            </Form.List>
            <Button type="primary" htmlType="submit" size={"large"} shape={"round"} style={{width: '100%'}}>
              Save / Update
            </Button>
          </Form>
        
        
      </Card>
    </>
  )
  
}

export default BrandProfileSection