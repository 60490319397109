import AuthenticationManager from "./AuthenticationManager";
import APIClient from "./APIClient";
import StorageManager, { StorageKeys } from "./StorageManager";
//@ts-ignore
import { REACT_APP_SERVER_URL } from "@env";

class LocalClient {
  private static init(): APIClient {
    const authenticationManager = new AuthenticationManager();

    authenticationManager.getToken = async (): Promise<string | null> => {
      return StorageManager.apiToken;
    };
    authenticationManager.setToken = async (token) => {
      await StorageManager.setApiToken(token);
    };
    authenticationManager.clearToken = async () => {
      await StorageManager.deleteItem(StorageKeys.API_TOKEN);
    };
    authenticationManager.tokenExist = async () => {
      return !!(await StorageManager.apiToken);
    };

    const client = new APIClient();
    client.baseUrl = process.env.REACT_APP_SERVER_URL as string;
    client.credentialManager = authenticationManager;
    return client;
  }

  static client(): APIClient {
    return LocalClient.init();
  }
}

const client = LocalClient.client();
export { client };
