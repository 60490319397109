import {FC} from "react";
import {Card, Col, Row, Skeleton, Statistic, Tag} from "antd";
import {ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Statistics} from "../chore/interfaces/statistics";
import {requestColor, RequestStatus, requestStatusName} from "../chore/enums/RequestStatus";

export type RequestStats = Record<string, any>
const RequestStatsCard: FC<{data: RequestStats, loading: boolean}> = ({data, loading}) => {
  
  
  
  return <div className="site-statistic-demo-card">
    <Row gutter={12}>
      
        {Object.keys(data).map((status) => {
          return (
            <Col span={4} style={{marginTop: 12}} key={status}>
              <Card>
                <Statistic
                  loading={loading}
                  title={<Tag title={requestStatusName(status as RequestStatus)} color={requestColor(status as RequestStatus)}>{requestStatusName(status as RequestStatus)}</Tag>}
                  value={
                    //@ts-ignore
                    parseInt(data[status])
                  }
                  precision={0}
                />
              </Card>
            </Col>
          )
        })}
    </Row>
  </div>
}

export default RequestStatsCard