import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import AuthenticationManager from "./AuthenticationManager";

const _successResponseHandler = (res: AxiosResponse) => {
  console.log(
    res.config.method,
    res.status,
    res.request.responseURL.replace(process.env.SERVER_URL, "")
  );
  return res;
};

const _errorResponseHandler = (error: AxiosError) => {
  console.log("-- Error reponse ---");
  console.log(JSON.stringify(error));
  console.log(error.response);
  return Promise.reject(error);
};

export default class APIClient {
  private _baseUrl!: string;

  set baseUrl(url: string) {
    this._baseUrl = url;
    this.configureInstance();
  }
  get baseUrl() {
    return this._baseUrl;
  }

  instance!: AxiosInstance;
  credentialManager!: AuthenticationManager;

  constructor(baseURL?: string) {
    if (baseURL) {
      this.configureInstance();
    }
  }

  configureInstance() {
    this.instance = axios.create({
      baseURL: this._baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "Bookr Client",
        "X-Requester-Version": "2.0",
      },
    });

    this.instance.interceptors.request.use((c) => {
      return c;
    });

    this.instance.interceptors.response.use(
      _successResponseHandler,
      _errorResponseHandler
    );
  }

  async request<T>(
    authenticated: boolean,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      let Authorization = "";
      if (authenticated) {
        let authToken = await this.credentialManager.getToken();
        Authorization = `Bearer ${authToken}`;
      }

      return this.instance.request<T, AxiosResponse<T>>({
        ...config,
        headers: {
          Authorization,
          ...config.headers,
        },
      });
    } catch (error) {
      throw error;
    }
  }
}
