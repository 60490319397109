import Base from "./Base";
import Link from "./Link";
import {CustomerType} from "./enums/CustomerType";
import User from "./User/User";
import Address from "./Address";
import Picture from "./Picture";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import {parseISO} from "date-fns";
import {InfluencerRelationship} from "./enums/InfluencerRelationship";
import {BrandType} from "./enums/BrandType";
import {OpeningHours} from "./interfaces/opening-hours";
import Deal from "./Deal";
import {Statistics} from "./interfaces/statistics";

export default class Brand extends Base {
  
  /* Topology */
  
  prefix = 'brd';
  entityType= 'Brand'
  name = '';
  slug = '';
  visible: boolean = true;
  public: boolean = true;
  slogan?: string;
  description?: string;
  position: number = 0
  phoneNumber?: string;
  owner!: User;
  
  
  address?: Address;

  
  type?: BrandType[] = [];
  profilePicture?: Picture;
  pictures?: Picture[];
  deals?: Deal[];
  requests?: Request[];
  links?: Link[];
  openingHours?: OpeningHours;
  status: CustomerType = CustomerType.access;
  
  completedRequests = 0;
  
  constructor() {
    super();
  }
  
  
  
  async create() {
  
    //@ts-ignore
    let data: Record<keyof Brand, any> = {...this}
    
    delete data.prefix
    delete data.entityType
    
    const response = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/brands',
      method: "POST",
      data
    });
    
    this.id = response.data.data.id;
    this.hash = response.data.data.hash;
    this.hash = response.data.data.hash;
    this.createdAt = response.data.data.createdAt;
    this.updatedAt = response.data.data.updatedAt;
    
    return this
  }
  
  async createInvoice(currency: string, price: number) {
    
    //@ts-ignore
    let data: Record<keyof Brand, any> = {...this}
    
    delete data.prefix
    delete data.entityType
    
    const response = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/brands/' + this.hash + '/invoices',
      method: "POST",
      data: {
        price, currency
      }
    });
    
    this.id = response.data.data.id;
    this.hash = response.data.data.hash;
    this.hash = response.data.data.hash;
    this.createdAt = response.data.data.createdAt;
    this.updatedAt = response.data.data.updatedAt;
    
    return this
  }
  
  static  async getAll(limit: number, skip: number, query?: string): Promise<Brand[]> {
    const {data} = await client.request<ServerResponseType<Brand[]>>(true, {
      url: '/v2/brands',
      params: {
        skip, limit, q: query
      }
    });
    return data.data
  }
  
  static async get(hash: string): Promise<Brand> {
    const {data} = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/brands/' + hash,
    });
    return Object.assign(
      new Brand(),
      {...data.data},
      {createdAt: parseISO(data.data.createdAt.toString()), updatedAt: parseISO(data.data.updatedAt.toString())})
  }
  
  static async stats(): Promise<Statistics> {
    const {data} = await client.request<ServerResponseType<Statistics>>(true, {
      url: '/v2/brands/stats',
    });
    return data.data
  }
  
  
  async get() {
    const {data} = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/brands/' + this.hash,
    });
    return Object.assign(
      new Brand(),
      {...data.data},
      {createdAt: parseISO(data.data.createdAt.toString()), updatedAt: parseISO(data.data.updatedAt.toString())})
  }
  
  async update() {
    //@ts-ignore
    let data: Record<keyof Brand, any> = {...this}
  
    delete data.prefix
    delete data.entityType
    
    const response = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/brands/' + this.hash,
      method: "PUT",
      data
    });
    
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<boolean>>(true, {
      url: '/v2/brands/' + this.hash,
      method: "DELETE",
      data: {
        ...this
      }
    });
    
    return status === 204
  }
  
  
}