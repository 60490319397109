import StatsCard from "../../components/StatsCard";
import {useEffect, useState} from "react";
import {Statistics} from "../../chore/interfaces/statistics";
import User from "../../chore/User/User";
import Brand from "../../chore/Brand";
import Influencer from "../../chore/Influencer";
import Request from "../../chore/Request";

const DashboardPage = () => {
  
  const [userStats, setUserStats] = useState<Statistics | undefined>()
  const [brandStats, setBrandStats] = useState<Statistics | undefined>()
  const [influencersStats, setInfluencersStats] = useState<Statistics | undefined>()
  const [requestsStats, setRequestsStats] = useState<Statistics | undefined>()
  
  
  useEffect(() => {
    User.stats().then(data => setUserStats(data))
    Brand.stats().then(data => setBrandStats(data))
    Influencer.stats().then(data => setInfluencersStats(data))
    Request.stats().then(data => setRequestsStats(data))
  }, [])
  
  return (
    <>
      <h1 style={{fontSize: '2em'}}>Dashboard</h1>
  
      <h2 style={{marginTop: 20}}>Users</h2>
      <StatsCard entity={"Users"} data={userStats}/>
      
      <h2 style={{marginTop: 20}}>Brands</h2>
      <StatsCard entity={"Brands"} data={brandStats}/>
  
      <h2 style={{marginTop: 20}}>Influencers</h2>
      <StatsCard entity={"Influencers"} data={influencersStats}/>
  
      <h2 style={{marginTop: 20}}>Requests</h2>
      <StatsCard entity={"Request"} data={requestsStats}/>
      
      
      
    </>
  )
}

export default DashboardPage