export default class Base {
  
  hash!: string;
  id!: number;
  createdAt!: Date | string;
  updatedAt!: Date | string;
  
  name?: string;
  
  prefix?: string = 'ba'
  entityType?: string = 'base'
  objectID!: string
  
  algoliaObject(): Partial<Base> {
    return {...this}
  }
  
}