import {
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  InputNumber,
  Modal, Pagination,
  Row,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { EditFilled } from '@ant-design/icons';
import { FC, useEffect, useRef, useState } from "react";
import Influencer from "../../chore/Influencer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Brand from "../../chore/Brand";
import useAlgolia, { HitArray, Indice } from "../../hooks/useAlgolia";
import Request from "../../chore/Request";
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear
} from "date-fns";
import { requestColor, RequestStatus, requestStatusName } from "../../chore/enums/RequestStatus";
import moment from "moment";
import ProfileType from "../../chore/enums/ProfileType";
import { Statistics } from "../../chore/interfaces/statistics";
import StatsCard from "../../components/StatsCard";
import RequestStatsCard, { RequestStats } from "../../components/RequestStatsCard";
import Moment from "moment";

const { Column, ColumnGroup } = Table;
const { Option } = AutoComplete;

const RequestsListPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [stats, setStats] = useState<Statistics>()
  const [requests, setRequests] = useState<Request[]>([])
  const [requestsData, setRequestsData] = useState<Record<string, any> | null>()
  const [newRequestModalVisible, setNewRequestModalVisible] = useState<boolean>(false);

  const { RangePicker } = DatePicker;
  const rangePickerRef = useRef(null)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await fetchRequests(3000, offset).then();
      await fetchRequestsStats().then()
      setLoading(false)
    }
    fetch().then()
  }, [offset, searchParams])

  const fetchRequests = async (limit: number, skip: number) => {
    let response = await Request.getAll(
      limit,
      skip,
      searchParams.get('brandId'),
      searchParams.get('influencerId'),
      searchParams.get('startDate') ? parseISO(searchParams.get('startDate') as string) : undefined,
      searchParams.get('endDate') ? parseISO(searchParams.get('endDate') as string) : undefined);
    setRequests(response)



  }

  const fetchRequestsStats = async () => {
    let response = await Request.stats(searchParams.get('influencerId'), searchParams.get('brandId'));
    setStats(response)
    setRequestsData(response.other)
  }

  const getByInterval = (start?: Moment.Moment | null, end?: Moment.Moment | null) => {
    const params: Record<string, string | null> = {}

    if (start) {
      params['startDate'] = formatISO(startOfDay(start.toDate()), { format: 'basic', representation: 'date' });
    }

    if (end) {
      params['endDate'] = formatISO(endOfDay(end.toDate()), { format: 'basic', representation: 'date' });
    }

    if (searchParams.get('brandId')) {
      params['brandId'] = searchParams.get('brandId');
    }
    if (searchParams.get('influencerId')) {
      params['influencerId'] = searchParams.get('influencerId');
    }
    //@ts-ignore
    setSearchParams(params)
  }

  const getByIntervalDate = (start?: Date, end?: Date) => {
    const params: Record<string, string | null> = {}

    if (start) {
      params['startDate'] = formatISO(startOfDay(start), { format: 'basic', representation: 'date' });
    }

    if (end) {
      params['endDate'] = formatISO(endOfDay(end), { format: 'basic', representation: 'date' });
    }

    if (searchParams.get('brandId')) {
      params['brandId'] = searchParams.get('brandId');
    }
    if (searchParams.get('influencerId')) {
      params['influencerId'] = searchParams.get('influencerId');
    }
    //@ts-ignore
    setSearchParams(params)
    //@ts-ignore
    rangePickerRef.current?.blur()
  }

  return (
    <>
      <Row style={{ flexDirection: 'row', flex: 1, justifyContent: "space-between" }}>
        <h1 style={{ fontSize: '2em' }}>Requests List</h1>
        <Button type={"primary"} onClick={() => setNewRequestModalVisible(true)}>Create a request</Button>
      </Row>

      <StatsCard entity={'Request'} data={stats} />
      {requestsData && <RequestStatsCard data={requestsData} loading={loading} />}
      <div>
        <h3>Select dates</h3>
        <RangePicker ref={rangePickerRef} onChange={(values) => {
          // @ts-ignore
          getByInterval(values[0] ?? null, values[1] ?? null)
        }} renderExtraFooter={() => <div><Button type={'ghost'} style={{ flex: 1 }} onClick={() => {
          getByIntervalDate(startOfMonth(new Date()), endOfMonth(new Date()))
        }}>This month</Button> <Button style={{ flex: 1 }} type={'ghost'} onClick={() => {
          getByIntervalDate(startOfYear(new Date()), endOfYear(new Date()))
        }}>This year</Button></div>} />
      </div>
      <div style={{ marginTop: 20 }}>
        {/* @ts-ignore */}
        <Table dataSource={requests} loading={loading} pagination={{ defaultPageSize: 50 }}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="Brand" dataIndex="brand" key="brand" render={item => item.name} />
          <Column title="Influencer" dataIndex="influencer" key="influencer" render={item => `${item.firstname} ${item.lastname}`} />
          <Column title="Initiator" dataIndex="null" key="initiator" render={(item, record: Request) => <Tag>{!!record.steps[0] ? record.steps[0].from : 'Unknown'}</Tag>} />
          <Column title="Date" dataIndex="dateTz" key="dateTz" render={(item, record: Request) => (record.dateTz ? format(parseISO((record.steps[record.steps.length - 1].dateTz as string).replace('Z', '')), 'dd/MM/yyyy HH:mm') : '??')} />
          <Column title="Archived"
            dataIndex="archived"
            key="archived"
            sorter={(a: Request, b: Request) => a.archived === a.archived ? 1 : -1}
            filters={
              [
                { text: 'Archived', value: true },
                { text: 'Visible', value: false },
              ]
            }
            onFilter={(value, request: Request) => request.archived === value}
            render={(item, record: Request) => <Tag color={record.archived ? 'red' : 'green'}>{record.archived ? 'Archived' : 'Visible'}</Tag>} />
          <Column title="Status"
            dataIndex="status"
            key="status"
            sorter={(a: Request, b: Request) => a.status.toLowerCase().localeCompare(b.status.toLowerCase())}
            filters={Array.from(new Set(requests?.map(req => req.status))).map((v) => {
              return { text: requestStatusName(v) as string, value: v as string }
            })}
            onFilter={(value, request: Request) => request.status.toLowerCase() === value}
            render={item => <Tag color={requestColor(item)}>{requestStatusName(item)}</Tag>} />
          <Column title={'Actions'} render={(_, record: Request) => {

            return <>
              <Button type={"primary"} href={'/requests/' + record.hash} shape={'round'}><EditFilled /> See request details</Button>
            </>
          }} />
        </Table>
      </div>

      <RequestModal visible={newRequestModalVisible} onClose={(request) => {
        if (request) {
          fetchRequests(400, offset).then()
        }
        setNewRequestModalVisible(false)
      }} />

    </>
  )
}


const RequestModal: FC<{ visible: boolean, onClose: (request?: Request) => void }> = ({ visible, onClose }) => {

  const [isVisible, setVisible] = useState(visible)
  const [request, setRequest] = useState<Request>(new Request());
  const [brands, setBrands] = useState<HitArray<Brand>>([]);
  const [influencers, setInfluencers] = useState<HitArray<Influencer>>([]);
  const [influencerLoading, setInfluencerLoading] = useState<boolean>(false);
  const [brandLoading, setBrandLoading] = useState<boolean>(false);
  const { search, get } = useAlgolia()

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  useEffect(() => {
    const req = new Request()
    req.seats = 2
    setRequest(req)
  }, [visible])

  const handleSearch = (value: string, indice: Indice) => {
    switch (indice) {
      case "Brands": {
        search<Brand>(indice, value).then((res) => setBrands(res));
        break;
      }
      case "Influencers": {
        search<Influencer>(indice, value).then((res) => {
          setInfluencers(res)
        });
        break;
      }
      default:
        break;
    }
  }

  return isVisible ? (
    <Modal visible={isVisible} onOk={async () => {
      try {
        let req = await request.create();
        message.success('Request created successfully')
        onClose(req)
      } catch (error) {
        message.error('Request creation failed')
        console.log(error)
      }
    }} onCancel={() => {
      onClose()
    }} width={650}>
      <h2>Create a New Request</h2>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <h3>Brand</h3>
          <AutoComplete style={{ width: '100%' }} onSearch={(value) => handleSearch(value, "Brands")} onSelect={async (value: string) => {
            setBrandLoading(true)
            request.brand = await get('Brands', value);
            setRequest(request);
            setBrandLoading(false)
          }}>
            {brands.map(brand => <Option value={brand.hash}>{brand.name}</Option>)}
          </AutoComplete>
          <Card style={{ marginTop: 12, borderRadius: 8 }} loading={brandLoading}>
            {request.brand ? <><strong>{request.brand.name}</strong><br />
              <small>{request.brand.description}</small><br /></> : <Empty style={{ marginTop: 12, borderRadius: 8 }} description={'Select a brand'} />}
          </Card>
        </Col>
        <Col span={12}>
          <h3>Influencer</h3>
          <AutoComplete style={{ width: '100%' }} onSearch={(value) => handleSearch(value, "Influencers")} onSelect={async (value: string) => {
            setInfluencerLoading(true)
            request.influencer = await get('Influencers', value);
            setRequest(request);
            setInfluencerLoading(false)
          }}>
            {influencers.map(influencer => <Option value={influencer.hash}>{influencer.firstname} {influencer.lastname}</Option>)}
          </AutoComplete>
          <Card style={{ marginTop: 12, borderRadius: 8 }} loading={influencerLoading}>
            {request.influencer ? <>
              <strong>{request.influencer.name}</strong><br />
              {request.influencer.firstname} {request.influencer.lastname}<br />
            </> : <Empty style={{ marginTop: 12, borderRadius: 8 }} description={'Select an influencer'} />}

          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          <label>Number of guest</label>
          <InputNumber defaultValue={request.seats ?? 2} min={1} max={10} style={{ width: '100%' }} onChange={(value) => {
            request.seats = value;
            setRequest(request)
          }} />
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          Date : <strong>(⚠️ bien cliquer sur le petit OK après modification)</strong><br />
          <DatePicker
            disabledDate={date => date.isBefore(moment())}
            style={{ width: '100%' }} format={'DD/MM/YYYY [at] HH:mm'}
            showTime={{ format: 'HH:mm' }}
            onOk={(date) => {
              request.date = date.toDate();
              setRequest(request)
            }} />
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          Message :
          <Input placeholder={'Optionnal Message'} onChange={e => {
            request.message = e.target.value;
            setRequest(request);
          }} />
        </Col>
      </Row>

      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          <label>On behalf of :</label><br />
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Select style={{ width: '100%' }} onSelect={(value: ProfileType) => {
            request.initiatedBy = value
            setRequest(request);
          }}>
            <Select.Option value={ProfileType.Influencer}>Influencer</Select.Option>
            <Select.Option value={ProfileType.Brand}>Brand</Select.Option>
          </Select>
        </Col>
      </Row>

    </Modal>
  ) : null
}

export default RequestsListPage
