import {useEffect, useState} from "react";
import {client} from "../chore/API/LocalClient";
import {UserStatus} from "../chore/enums/UserStatus";

interface IPayload {
  aud: string
  email: string
  exp: number
  iat: number
  iss: string
  jwtid: string
  status: UserStatus
  userId: string
}
export default function useAuth() {
  
  
  
  const [authed, isAuthed] = useState<boolean | undefined>()
  const [payload, setPayload] = useState<IPayload | undefined>()
  
  function parseJwt (token: string): IPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
  }
  
  
  useEffect((() => {
    if(!client.credentialManager.tokenExist()) {
      isAuthed(false);
    } else {
      client.credentialManager.getToken().then(token => {
        if(token) {
          const payload = parseJwt(token);
          setPayload(payload)
          if(['admin', 'super-admin'].includes(payload.status)) {
            isAuthed(true)
          } else {
            client.credentialManager.clearToken()
            isAuthed(false)
          }
          
        } else {
          isAuthed(false)
        }
      })
      
    }
    
  }), [])
  
  
  return {authed, payload}
}