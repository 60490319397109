import {Button, Card, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";
import InfluencerProfileSection from "../../components/profile/InfluencerProfileSection";
import AddressCard from "../../components/profile/AddressCard";
import ProfileType from "../../chore/enums/ProfileType";
import PictureCard from "../../components/profile/PicturesCard";
import LinksCard from "../../components/profile/LinksCard";
import {LinkOutlined} from "@ant-design/icons";
import PhotographerProfileSection from "../../components/profile/PhotographerProfileSection";
import Photographer from "../../chore/Photographers";

const PhotographerDetailPage = () => {
  
  const [photographer, setPhotographer] = useState<Photographer | undefined>()
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    
    if(id !== 'new') {
      fetchPhotographer(id).then()
    } else {
      const influ = new Photographer();
      setPhotographer(influ)
    }
    
  }, [])
  
  
  
  const fetchPhotographer = async (hash: string) => {
    let response = await Photographer.get(hash);
    setPhotographer(response)
  }
  
  if(!photographer) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{photographer.name}</h1>
      </Row>
  
      <EntityCard entity={photographer}/>
      <PhotographerProfileSection photographerId={id} onUpdate={photographer => navigate((photographer ? '../photographers/' + photographer.hash : '../photographers'), {replace: true})}/>
      {!!photographer.hash ? <>
        <AddressCard address={photographer.address} entityType={ProfileType.Photographer} entityId={photographer.hash}
                     name={photographer.name}/>
        <PictureCard entityType={ProfileType.Photographer} entityId={photographer.hash}
                     name={photographer.name}/>
        <LinksCard links={photographer.links} entityType={ProfileType.Photographer} entityId={photographer.hash}
                   name={photographer.name}/>
        <Card style={{borderRadius: 12, margin: 12}}>
          <h2 style={{fontSize: '1.5em'}}>Other links</h2>
          <Divider/>
          <div style={{display: 'flex', flexDirection: 'row', gap: 40}}>
            <Button size={"large"} style={{borderRadius: 12, backgroundColor: "blueviolet"}} href={'/users/' + photographer?.user?.hash} block icon={<LinkOutlined/>}>
              See {photographer.name} user
            </Button>
          </div>
    
        </Card>
      </> : <div/>}
      
    </>
  )
}

export default PhotographerDetailPage
