import React, {FC} from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import moment from 'moment-timezone'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

moment.tz.setDefault("UTC");

const RootApp: FC  = () => {
  return (<React.StrictMode>
    <App/>
  </React.StrictMode>);
}


root.render(
  <RootApp/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker();
reportWebVitals();
