import {DragEvent, FC, useEffect, useState} from "react";
import {message, Modal, Upload} from "antd";
import PictureType from "../../chore/enums/PictureType";
import ProfileType from "../../chore/enums/ProfileType";
import Picture, {PictureStatus} from "../../chore/Picture";

const { Dragger } = Upload;


const PictureUploadModal: FC<{multiple?: boolean, isVisible: boolean, pictureType: PictureType, name: string, entityType: ProfileType, entityId: string, onClose?: (picture?: Picture[] ) => void}> = ({multiple = true,isVisible, pictureType, name, entityId, entityType, onClose}) => {
  
  useEffect(() => {
    setIsModalVisible(isVisible)
  }, [isVisible])
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [isMultiple, setMultiple] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList>();
  const [pictures, setPictures] = useState<Picture[]>([]);
  
  
  /* Uploder */
  
  const props = {
    name: 'file',
    multiple: multiple,
    showUploadList: false,
    customRequest: () => {
      return
    },
    beforeUpload: (file: File) => {
      const isAllowed = ['image/png', 'image/jpeg'].includes(file.type)
      if (!isAllowed) {
        message.error(`${file.name} is not a png, nor a jpeg file`);
      }
      return isAllowed || Upload.LIST_IGNORE;
    },
    action: async (file: File) => {
      const p = new Picture();
      p.file = file
      p.pictureType = pictureType
      await p.createUploadUrl(entityType, entityId);
      await p.upload((progress) => setProgress(progress * 100))
      console.log('upload', p);
      p.status = PictureStatus.Validated;
      await p.update();
      const picture = await p.get();
      if(!isMultiple && onClose) {
        onClose([picture])
        setIsModalVisible(false)
      }
      return file.name
    } ,
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`).then();
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`).then();
      }
    },
    onDrop(e: DragEvent<HTMLDivElement>) {
      setMultiple(e.dataTransfer.files.length > 1)
      setFiles(e.dataTransfer.files);
    },
  };
  
  
  return (
    <Modal
      title={`Upload a new ${pictureType === PictureType.ProfilePicture ? 'profile picture' : 'photo'} for ${name}`}
      visible={isModalVisible}
      footer={null}
      okText={isMultiple ? 'Done !' : undefined}
      onOk={() => {
        setIsModalVisible(false);
        if(onClose) {
          onClose(pictures)
        }
      }}
      onCancel={() => {
        setIsModalVisible(false)
        if(onClose) {
          onClose()
        }
      }}>
  
      <Dragger {...props}>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          {progress > 0 ? `Uploading ${progress.toFixed(2)} %` : 'Support for a single upload.'}
        </p>
      </Dragger>
      <div>
        {pictures.map((p) => {
          
          return <div>
            <img src={p.fullSizeUrl} width={30} height={30} style={{objectFit: 'contain'}}/>
            {p.name}
          </div>
        })}
      </div>
      
    </Modal>
  )
}

export default PictureUploadModal