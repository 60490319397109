import {Button, Card, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";
import Deal from "../../chore/Deal";
import DealDetailsSection from "../../components/profile/DealDetailsSection";

const DealDetailPage = () => {
  
  const [deal, setDeal] = useState<Deal>(new Deal())
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    fetchDeal(id).then()
  }, [])
  
  
  const fetchDeal = async (hash: string) => {
    let deal = new Deal();
    if(hash !== 'new') {
      deal.hash = hash
      deal = await deal.get()
    }
    setDeal(deal)
  }
  
  if(!deal) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{deal.name}</h1>
      </Row>
  
      <EntityCard entity={deal} name={'Deal for ' + deal.brand?.name}/>
      <DealDetailsSection deal={deal} onUpdate={(deal) => {if(deal) {setDeal(deal)} else { }}}/>
    </>
  )
}

export default DealDetailPage