export enum InfluencerRelationship {
  unknown = "unknown",
  single = 'single',
  in_couple = 'in_couple',
  married = 'married',
  divorced = 'divorced',
  widow = 'widow',
}


export const relationName = (status: InfluencerRelationship) => {
  switch (status) {
    case InfluencerRelationship.divorced:
      return "Divorced 💔";
    case InfluencerRelationship.in_couple:
      return "In a Relationship 👩‍❤️‍👨";
    case InfluencerRelationship.married:
      return "Married 💍";
    case InfluencerRelationship.single:
      return "Single 😍";
    case InfluencerRelationship.widow:
      return "Widowed ⚰️";
    case InfluencerRelationship.unknown:
      return "Inconnu 🫥";
  }
}