import React, {FC} from 'react';
import {Button} from 'antd'
import LoginPage from "./pages/Login/Login";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Layout from "./components/Layout";
import DefaultPage from "./pages/DefaultPage/DefaultPage";
import DashboardPage from "./pages/Dashboard/Dashboard";
import UsersListPage from "./pages/Users/UserListPage";
import InfluencersListPage from "./pages/Influencers/InfluencersListPage";
import InfluencersDetailPage from "./pages/Influencers/InfluencersDetailPage";
import DealsListPage from "./pages/Deals/DealsListPage";
import BrandDetailPage from "./pages/Brands/BrandDetailPage";
import PhotographersListPage from "./pages/Photographers/PhotographersListPage";
import PhotographerDetailPage from "./pages/Photographers/PhotographerDetailPage";
import UsersDetailPage from "./pages/Users/UsersDetailPage";
import DealDetailPage from "./pages/Deals/DealDetailPage";
import BrandsListPage from "./pages/Brands/BrandsListPage";
import RequestsListPage from "./pages/Requests/RequestsListPage";
import RequestDetailPage from "./pages/Requests/RequestDetailPage";
import PicturesPage from "./pages/Pictures/PicturesPages";
import TestimonialListPage from "./pages/Testimonial/TestimonialsListPage";
import TestimonialsDetailPage from "./pages/Testimonial/TestimonialsDetailPage";
import MessagesListPage from "./pages/Messages/MessagesListPage";
import LinkPage from "./pages/Links/LinkPage";


const App: FC = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
          <Route path={'/login'} element={<LoginPage/>}/>
          <Route path={'/'} element={<Layout/>}>
          <Route path={'dashboard'} element={<DashboardPage/>}/>
          <Route path={'users'} element={<UsersListPage/>}/>
          <Route path={'users/:id'} element={<UsersDetailPage/>}/>
          <Route path={'influencers'} element={<InfluencersListPage/>}/>
          <Route path={'influencers/:id'} element={<InfluencersDetailPage/>}/>
          <Route path={'photographers'} element={<PhotographersListPage/>}/>
          <Route path={'photographers/:id'} element={<PhotographerDetailPage/>}/>
          <Route path={'brands'} element={<BrandsListPage/>}/>
          <Route path={'brands/:id'} element={<BrandDetailPage/>}/>
          <Route path={'deals'} element={<DealsListPage/>}/>
          <Route path={'deals/:id'} element={<DealDetailPage/>}/>
          <Route path={'requests'} element={<RequestsListPage/>}/>
          <Route path={'requests/:id'} element={<RequestDetailPage/>}/>
          <Route path={'testimonials'} element={<TestimonialListPage/>}/>
          <Route path={'messages'} element={<MessagesListPage/>}/>
          <Route path={'testimonials/:id'} element={<TestimonialsDetailPage/>}/>
          <Route path={'pictures'} element={<PicturesPage/>}/>
          <Route path={'social-links'} element={<LinkPage/>}/>
          <Route path={'logs'} element={<DefaultPage title={'Logs'} />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
);
export default App
