import {FC} from "react";

const DefaultPage: FC<{title: string, description?: string}> = ({title, description})=> {
  return <div>
    <h1 style={{fontSize: '2em'}}>{title}</h1>
    <p>{description}</p>
  </div>
}

export default DefaultPage
