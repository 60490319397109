import {FC} from "react";
import {Card, Col, Row, Skeleton, Statistic} from "antd";
import {ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Statistics} from "../chore/interfaces/statistics";


const StatsCard: FC<{entity: string, data?: Statistics}> = ({entity, data}) => {
  
  const getColorAndIcon = (number: number | undefined) => {
    if(number && number < 100) {
      return {
        icon: <ArrowDownOutlined />,
        color: '#cf1322'
      }
    } else if (number &&  number > 100) {
      return {
        icon: <ArrowUpOutlined />,
        color: '#3f8600'
      }
    } else {
        return {
          icon: <ArrowRightOutlined />,
          color: '#7d7d7d'
        }
    }
  }
  
  return <div className="site-statistic-demo-card">
    <Row gutter={16}>
      <Col span={6}>
        <Card>
          <Statistic
            loading={!data?.week}
            title={"New " + entity + " this week"}
            value={data?.week.current}
            precision={0}
            valueStyle={{ color: getColorAndIcon(data?.week.variation).color }}
            prefix={getColorAndIcon(data?.week.variation).icon}
          />
          {!data?.week ? <Skeleton active /> : <span style={{color: '#b1b1b1'}}>{data?.week.past} last week ({data?.week.variation} %)</span>}
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={!data?.month}
            title={"New " + entity + " this month"}
            value={data?.month.current}
            precision={0}
            valueStyle={{ color: getColorAndIcon(data?.month.variation).color }}
            prefix={getColorAndIcon(data?.month.variation).icon}
          />
          {!data?.month ? <Skeleton active /> : <span style={{color: '#b1b1b1'}}>{data?.month.past} last month ({data?.month.variation} %)</span>}
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={!data?.year}
            title={"New " + entity + " this year"}
            value={data?.year.current}
            precision={0}
            valueStyle={{ color: getColorAndIcon(data?.year.variation).color }}
            prefix={getColorAndIcon(data?.year.variation).icon}
          />
          {!data?.year ? <Skeleton active /> : <span style={{color: '#b1b1b1'}}>{data?.year.past} last year ({data?.year.variation} %)</span>}
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={!data?.total}
            title={entity + " overall"}
            value={data?.total}
            precision={0}
            valueStyle={{ color: '#1a1a1a' }}
          />
          <span>&nbsp;</span>
        </Card>
      </Col>
    </Row>
  </div>
}

export default StatsCard