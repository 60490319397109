import {Layout, DatePicker, Menu, Breadcrumb, Form, Input, Button, Alert} from 'antd';
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {useState} from "react";
import User from "../../chore/User/User";
import {useNavigate} from "react-router-dom";
const { Content, Header, Footer } = Layout;


const LoginPage = () => {
  
  const navigate = useNavigate()
  
  const [success, setSuccess] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [loading, isLoading] = useState(false);
  
  const handleLogin = async (values: {email: string, password: string}) => {
    
    const login = await User.login(values.email, values.password, 'admin')
      .catch(error => {
        if(error.response.status === 401) setError('Invalid credential please check login / password');
      })
      .finally(() => isLoading(false))
    
    if(login && login.hash) {
      const user = await User.getCurrent()
      console.log(user)
      setSuccess('Welcome back ' + user.firstname + ' you\'ll be redirected in a few seconds !')
      setTimeout(() => {
        navigate('../dashboard', {replace: true})
      }, 2000)
    }
  }
  
  
  return (
    <Layout className="layout" style={{ display:'flex', backgroundColor: "white", justifyContent: "center", height: '100vh' }}>
      <Content style={{height: 'fit-content', justifyContent: "center",  display:'flex', alignItems: 'center'}}>
        <div className="site-layout-content" style={{backgroundColor: "#f0f2f5", padding: '50px', width:'50%'}}>
          <h1>Administration Bookr.</h1>
          <Form layout={"vertical"} onFinish={handleLogin}>
            {!!error && <Alert
              style={{marginBottom: 12}}
              message="Whoops !"
              description={error}
              type="error"
              showIcon
              closable
            />}
            {!!success && <Alert
              style={{marginBottom: 12}}
              message="Wonderful"
              description={success}
              banner={true}
              type="success"
              showIcon
            />}
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email is required' }]}>
              <Input placeholder="Email" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item  name="password" label="Password" rules={[{ required: true, message: 'Password is required' }]}>
              <Input.Password placeholder="Password" prefix={<LockOutlined />} />
            </Form.Item>
            <Button disabled={loading} loading={loading} type={"primary"} htmlType={"submit"} style={{width: '100%'}}>Login</Button>
          </Form>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>© Bookr 2022 - Created by Bits'n Coffee</Footer>
    </Layout>
  )
}

export default LoginPage