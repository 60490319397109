import Base from "../Base";
import {UserStatus} from "../enums/UserStatus";
import {UserGender} from "../enums/UserGender";
import Brand from "../Brand";
import Influencer from "../Influencer";
import Picture from "../Picture";
import {client} from "../API/LocalClient";
import {ServerResponseType} from "../API/types";
import Photographer from "../Photographers";
import {Statistics} from "../interfaces/statistics";


export default class User extends Base {
  
  prefix = 'usr';
  entityType = 'User';
  firstname?: string;
  lastname?: string;
  name?: string;
  email?: string;
  hasConfirmedEmail?: boolean = false;
  accountValidated?: boolean = false;
  salt?: string;
  password?: string;
  phoneNumber?: string;
  forceSMS?: boolean;
  status: UserStatus = UserStatus.pending;
  gender?: UserGender = UserGender.unspecified;
  brands?: Brand[];
  influencers?: Influencer[];
  photographers?: Photographer[];
  profilePicture?: Picture;
  lastConnexion: Date | string = new Date();
  lastConnexionPlatform: 'web' | 'admin' | 'mobile' = 'web';
  pictures: Picture[] = []
  
  
  static async fetchAll(skip: number, limit: number, query?: string): Promise<User[]> {
    const {data} = await client.request<ServerResponseType<User[]>>(true, {
      url: '/v2/users',
      params: {
        skip, limit, q: query
      }
    });
    return data.data
  }
  
  static async login(email: string, password: string, platform: 'web' | 'admin' | 'mobile'): Promise<{ hash: string, access_token: string }> {
    const {data, status} = await client.request<ServerResponseType<{ hash: string, access_token: string }>>(true, {
      url: '/v2/users/login',
      method: 'PATCH',
      data: {
        email, password, platform
      }
    });
    
    if(status === 200) {
      client.credentialManager.setToken(data.data.access_token)
    }
    
    return data.data
  }
  
  static async getCurrent(): Promise<User> {
    const {data, status} = await client.request<ServerResponseType<User>>(true, {
      url: '/v2/users/me',
    });
    return data.data
  }
  
  static async get(hash: string): Promise<User> {
    const {data} = await client.request<ServerResponseType<User>>(true, {
      url: '/v2/users/' + hash,
    });
    
    let u = data.data;
    return Object.assign(new User(), u)
  }
  
  static async stats(): Promise<Statistics> {
    const {data} = await client.request<ServerResponseType<Statistics>>(true, {
      url: '/v2/users/stats',
    });
    return data.data
  }
  
  async update(): Promise<User> {
    const {data} = await client.request<ServerResponseType<User>>(true, {
      url: '/v2/users/' + this.hash,
      method: "PUT",
      data: {
        ...this
      }
    });
    return Object.assign(new User(), data.data)
  }
  
  async getAdminLink(): Promise<User> {
    const {data} = await client.request<ServerResponseType<any>>(true, {
      url: '/v2/users/' + this.hash + '/admin-link',
      method: "GET",
    });
    return data.data
  }
  
  async resetPassword(email: string) {
    await client.request<ServerResponseType<User>>(false, {
      url: '/v2/users/request-password',
      method: "PATCH",
      data: {
        email
      }
    });
  }
  
  
  async delete() {
    await client.request<ServerResponseType<User>>(true, {
      url: '/v2/users/' + this.hash,
      method: "DELETE"
    });
  }
  
  canEdit(adminRole?: UserStatus): boolean {
    
    if(adminRole === UserStatus.admin && this.status === UserStatus.superAdmin)
      return false;
    if(adminRole === UserStatus.admin && this.status === UserStatus.admin)
      return true;
    else
      return true
  }
  
}
