import Base from "../../chore/Base";
import {Card, Col, Divider, Row} from "antd";
import {FC} from "react";
import {format, parseISO} from "date-fns";
import {SaveOutlined} from '@ant-design/icons'

const EntityCard: FC<{entity: Base, name?: string}> = ({entity, name}) => {
  
  const NotSaved = () => <span style={{color: '#a40b0b'}}><SaveOutlined color={'#a40b0b'}/> Not yet saved </span>
  
  return (
    <Card style={{flex: 1, flexDirection: 'column', borderRadius: 12, margin: 12}}>
      <h2 style={{fontSize: '1.5em'}}>Informations about {name}</h2>
      <Divider/>
      <Row>
        <Col span={8}>
          Entity Type : <strong><code>{entity.entityType}</code></strong>
        </Col>
        <Col span={8}>
          {entity.entityType} Hash : <strong>{entity.hash ? <code>{entity.hash}</code> : <NotSaved />}</strong>
        </Col>
        <Col span={8}>
           Id : <strong>{!isNaN(entity.id) ? <code>{entity.id}</code>  : <NotSaved />}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          Created at : <strong>{entity.createdAt ? format(typeof entity.createdAt === 'string' ?  parseISO(entity.createdAt) : entity.createdAt, `MMMM dd yyyy 'at' HH:mm`) : <NotSaved/>} </strong>
        </Col>
        <Col>
          Last updated at : <strong>{entity.updatedAt ? format(typeof entity.updatedAt === 'string' ? parseISO(entity.updatedAt) : entity.updatedAt, `MMMM dd yyyy 'at' HH:mm`) : <NotSaved/>} </strong>
        </Col>
      </Row>
    </Card>
  )
}

export default EntityCard