import Base from "./Base";
import Influencer from "./Influencer";
import ProfileType from "./enums/ProfileType";
import PictureType from "./enums/PictureType";
import {client} from "./API/LocalClient";
import axios from 'axios';
import {ServerResponseType} from "./API/types";
import Brand from "./Brand";


export enum PictureStatus {
  PendingUpload = 'pending_upload',
  AwaitingApproval = 'awaiting_approval',
  Validated = 'validated',
  Rejected = 'rejected',
}
export default class Picture extends Base {
  
  prefix = 'pict';
  entityType= 'Picture';
  fullSizeKey = '';
  squaredCropKey = '';
  status = PictureStatus.PendingUpload;
  pictureType = PictureType.Asset;
  isValidated = false;
  order: number | undefined = undefined;
  size?: number;
  brand?: Brand;
  influencer?: Influencer;
  photographer?: Influencer;
  fullSizeUrl?: string;
  squaredCropUrl?: string;
  file?: File;
  
  private uploadUrl?: string;
  
  
  static async getAll(params: { entity?: ProfileType, entityId?: string, status?: PictureStatus, limit?: number, offset?: number }) {
    let {data} = await client.request<ServerResponseType<Picture[]>>(true, {
      url: '/v2/pictures',
      params: {...params}
    });
    return data.data.map(p => Object.assign(new Picture(), p, {pictureType: p.pictureType}))
    
  }
  
  async get() {
    let {data} = await client.request<ServerResponseType<Picture[]>>(true, {
      url: '/v2/pictures/' + this.hash,
    });
    return Object.assign(new Picture(), data.data)
    
  }
  
  async createUploadUrl(entity: ProfileType, entityId: string) {
    console.log({entityId, entity, pictureType: this.pictureType, name: this.file?.name})
    let {data} = await client.request<ServerResponseType<Picture>>(true, {
      url: '/v2/pictures',
      method: 'POST',
      data: {entityId, entity, pictureType: this.pictureType, name: this.file?.name}
    });
    this.uploadUrl = data.data.uploadUrl;
    this.hash = data.data.hash;
    this.id = data.data.id;
  }
  
  async upload(onProgress?: (progress: number) => void) {
    
    if(!this.uploadUrl) return;
    
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    
    try {
      await axios.put(this.uploadUrl, this.file, {
        onUploadProgress: (progress: ProgressEvent) => {
          return onProgress
            ? onProgress(progress.loaded / progress.total)
            : null;
        },
        ...options,
      });
    } catch (error) {
      throw error;
    }
  }
  
  async update() {
    
    try {
      await client.request<ServerResponseType<Picture>>(true, {
        url: `/v2/pictures/${this.hash}`,
        data: {status: this.status, pictureType: this.pictureType, order: this.order},
        method: 'PATCH'
      });
    } catch (error) {
      throw error;
    }
    
    return this;
  }
  
  async delete() {
    
    try {
      await client.request<ServerResponseType<Picture>>(true, {
        url: `/v2/pictures/${this.hash}`,
        method: 'DELETE'
      });
    } catch (error) {
      throw error;
    }
  }
  
  getType(): string {
    switch (this.pictureType) {
      case PictureType.Asset:
        return 'Asset';
      case PictureType.ProfilePicture:
        return 'Profile Picture'
      case PictureType.Testimonial:
        return 'Testimonial'
    }
  }
  getTypeColor(): string {
    switch (this.pictureType) {
      case PictureType.Asset:
        return '#73dcc3';
      case PictureType.ProfilePicture:
        return '#1665c6';
      case PictureType.Testimonial:
        return '#d4ac0f';
    }
  }
  
  getStatus(): string {
    switch (this.status) {
      case PictureStatus.PendingUpload:
        return 'Pending Upload';
      case PictureStatus.AwaitingApproval:
        return 'Awaiting Approval';
      case PictureStatus.Validated:
        return 'Validated';
      case PictureStatus.Rejected:
        return 'Rejected';
    }
  }
  
  static getStatus(status: PictureStatus): string {
    switch (status) {
      case PictureStatus.PendingUpload:
        return 'Pending Upload';
      case PictureStatus.AwaitingApproval:
        return 'Awaiting Approval';
      case PictureStatus.Validated:
        return 'Validated';
      case PictureStatus.Rejected:
        return 'Rejected';
    }
  }
  
  getStatusColor(): string {
    switch (this.status) {
      case PictureStatus.PendingUpload:
        return '#bcbcbc';
      case PictureStatus.AwaitingApproval:
        return '#f5c70c';
      case PictureStatus.Validated:
        return '#176e18';
      case PictureStatus.Rejected:
        return '#a91235';
    }
  }
  
}

  
  
