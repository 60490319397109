import Base from "./Base";
import Link from "./Link";
import {CustomerType} from "./enums/CustomerType";
import User from "./User/User";
import Address from "./Address";
import Picture from "./Picture";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import {parseISO} from "date-fns";
import {InfluencerRelationship} from "./enums/InfluencerRelationship";
import {Statistics} from "./interfaces/statistics";

export default class Influencer extends Base {
  
  /* Topology */
  
  prefix = 'infl';
  entityType= 'Influencer'
  firstname = '';
  lastname = '';
  name = '';
  slug = '';
  cities: string[] = [];
  biography?: string;
  dateOfBirth?: Date | string | undefined = undefined;
  position: number = 0
  visible: boolean = false
  status: CustomerType = CustomerType.access;
  categories: string[] = [];
  user?: User;
  phoneNumber?: string;
  address?: Address;
  profilePicture?: Picture;
  pictures?: Picture[];
  requests?: Request[];
  links?: Link[]
  completedRequests = 0;
  relationship: InfluencerRelationship = InfluencerRelationship.single;
  
  async create() {
  
    //@ts-ignore
    let dataInfluencer: Record<keyof Influencer, any> = {...this}
    
    delete dataInfluencer.prefix
    delete dataInfluencer.hash
    delete dataInfluencer.entityType
    
    const {data} = await client.request<ServerResponseType<Influencer>>(true, {
      url: '/v2/influencers',
      method: "POST",
      data: dataInfluencer
    });
    
    this.id = data.data.id;
    this.hash = data.data.hash;
    
    return this
  }
  
  static  async getAll(limit: number, skip: number, query?: string): Promise<Influencer[]> {
    const {data} = await client.request<ServerResponseType<Influencer[]>>(true, {
      url: '/v2/influencers',
      params: {
        skip, limit, query
      }
    });
    return data.data
  }
  static async stats(): Promise<Statistics> {
    const {data} = await client.request<ServerResponseType<Statistics>>(true, {
      url: '/v2/influencers/stats',
    });
    return data.data
  }
  
  static async get(hash: string): Promise<Influencer> {
    const {data} = await client.request<ServerResponseType<Influencer>>(true, {
      url: '/v2/influencers/' + hash,
    });
    return Object.assign(
      new Influencer(),
      {...data.data},
      {
        createdAt: parseISO(data.data.createdAt.toString()),
        updatedAt: parseISO(data.data.updatedAt.toString()),
        dateOfBirth: data.data.dateOfBirth ? parseISO(data.data.dateOfBirth as string) : undefined
      })
  }
  
  async get() {
    const {data} = await client.request<ServerResponseType<Influencer>>(true, {
      url: '/v2/influencers/' + this.hash,
    });
    return Object.assign(
      new Influencer(),
      {...data.data},
      {createdAt: parseISO(data.data.createdAt.toString()), updatedAt: parseISO(data.data.updatedAt.toString())})
  }
  
  
  async update() {
    const {data} = await client.request<ServerResponseType<Influencer>>(true, {
      url: '/v2/influencers/' + this.hash,
      method: "PUT",
      data: {
        ...this
      }
    });
    console.log('update', data)
    
    
    return Object.assign(new Influencer(), data.data)
    
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<boolean>>(true, {
      url: '/v2/influencers/' + this.hash,
      method: "DELETE",
      data: {
        ...this
      }
    });
    
    return status === 204
  }
  
  
}