import algoliasearch, {AlgoliaSearchOptions} from "algoliasearch";
import Base from "../chore/Base";
import {HighlightResult, RankingInfo, SnippetResult, SearchResponse, SearchOptions} from "@algolia/client-search";

export type Indice = 'Users' | 'Photographers' | 'Influencers' | 'Brands';

export declare type Hit<THit> = THit & {
  readonly objectID: string;
  readonly _highlightResult?: HighlightResult<THit>;
  readonly _snippetResult?: SnippetResult<THit>;
  readonly _rankingInfo?: RankingInfo;
  readonly _distinctSeqID?: number;
};


export declare type HitArray<THit> = Array<Hit<Partial<THit>>>

export default function useAlgolia() {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID as string, process.env.REACT_APP_ALGOLIA_SEARCH_KEY  as string);
  
  async function search<T extends Base>(indice: Indice, query: string, options?: AlgoliaSearchOptions): Promise<HitArray<T>> {
    let results = await client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + indice).search<T>(query, { hitsPerPage: 1000, options});
    return results.hits
  }

  async function searchWithPagination<T extends Base>(indice: Indice, query: string, filters: string, options?: SearchOptions): Promise<SearchResponse<T>> {
    let results = await client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + indice).search<T>(query, { ...options, filters});
    return results
  }
  
  async function get<T extends Base>(indice: Indice, objectId: string): Promise<Hit<T>> {
    let result = await client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + indice).getObject<T>(objectId);
    return result
  }
  
  return { client, search, searchWithPagination, get }
  
  
}
