export enum SocialNetworkType {
  Instagram = 'instagram', // ✅
  Youtube = 'youtube',
  Tiktok = 'tiktok',
  Twitter = 'twitter',
  Pinterest = 'pinterest',
  SnapChat = 'snapchat',
  Facebook = 'facebook',
  Website = 'website',
}
