import Base from "./Base";
import Brand from "./Brand";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import {parseISO} from "date-fns";

export default class Deal extends Base {
  
  name?: string;
  prefix: string = 'deal'
  entityType: string = 'Deal'
  
  minFollower: number = 0
  maxFollower?: number;
  
  offer: string = '';
  
  stories: number = 0;
  storiesDetails?: string ;
  
  tags: string[] = []
  tagsDetails?: string;
  
  geolocation = true;
  geolocationNumber = 0;
  geolocationDetails?: string;
  
  posts?: number;
  postsDetails?: string;
  instagramGuide?: boolean;
  instagramGuideDurationMonth?: number;
  otherInformations?: string;
  
  brand?:Brand
  brandId?: string;
  archived?: boolean;
  
  async create() {
    
    //@ts-ignore
    let data: Record<keyof Deal, any> = {...this}
    
    delete data.prefix
    delete data.entityType
    
    const response = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/deals',
      method: "POST",
      data
    });
    
    this.id = response.data.data.id;
    this.hash = response.data.data.hash;
    this.createdAt = response.data.data.createdAt;
    this.updatedAt = response.data.data.updatedAt;
    
    return this
  }
  
  static  async getAll(limit: number, skip: number, brandId?: string): Promise<Deal[]> {
    const {data} = await client.request<ServerResponseType<Deal[]>>(true, {
      url: '/v2/deals',
      params: {
        skip, limit, brandId
      }
    });
    return data.data
  }
  
  async get(): Promise<Deal> {
    const {data} = await client.request<ServerResponseType<Brand>>(true, {
      url: '/v2/deals/' + this.hash,
    });
    return Object.assign(
      new Deal(),
      {...data.data},
      {createdAt: parseISO(data.data.createdAt.toString()), updatedAt: parseISO(data.data.updatedAt.toString())})
  }
  
  async update() {
    //@ts-ignore
    let data: Record<keyof Deal, any> = {...this}
    
    delete data.prefix
    delete data.entityType
    
    const res = await client.request<ServerResponseType<Deal>>(true, {
      url: '/v2/deals/' + this.hash,
      method: "PUT",
      data
    });
    
    this.hash = res.data.data.hash;
    this.id = res.data.data.id
    
  }
  
  async archive() {
    //@ts-ignore
    
    const response = await client.request<ServerResponseType<Deal>>(true, {
      url: '/v2/deals/' + this.hash + '/archive',
      method: "PATCH",
      data: {archive: !this.archived}
    });
    
    this.archived = response.data.data.archived;
    this.updatedAt = response.data.data.updatedAt;
    return this;
    
  }
  
  async delete(): Promise<boolean> {
    const {status} = await client.request<ServerResponseType<boolean>>(true, {
      url: '/v2/deals/' + this.hash,
      method: "DELETE",
      data: {
        ...this
      }
    });
    
    return status === 204
  }
  
}