import {DragEvent, FC, useEffect, useRef, useState} from "react";
import {Button, Card, Empty, Input, message, Modal, Upload} from "antd";
import PictureType from "../../chore/enums/PictureType";
import ProfileType from "../../chore/enums/ProfileType";
import Picture, {PictureStatus} from "../../chore/Picture";
import User from "../../chore/User/User";



const AttachUserModal: FC<{isVisible: boolean, name: string, entityType: ProfileType, entityId: string, onClose: (user: User | undefined) => void}> = ({isVisible, name, entityId, entityType, onClose}) => {
  
  useEffect(() => {
    setIsModalVisible(isVisible)
  }, [isVisible])
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [possibleUser, setPossibleUsers] = useState<User[]>([]);
  const [selectedUser, setUser] = useState<User>();
  const timeout = useRef<NodeJS.Timeout>()
  
  
  const handleInput = (query: string) => {
    if(timeout.current) clearTimeout(timeout.current)
    
    timeout.current = setTimeout(() => {
      User.fetchAll(0, 20, query).then(users =>  setPossibleUsers(users))
    }, 400)
    
  }
  
  const handleClick = () => {
    setPossibleUsers([])
    setIsModalVisible(false)
    onClose(selectedUser)
    setUser(undefined)
  }
  
  
  
 
  
  return (
    <Modal
      title={`Attach a user to ${name}`}
      visible={isModalVisible}
      footer={null}
      onCancel={() => {
        setIsModalVisible(false)
        onClose(undefined)
      }}>
  
      <Input placeholder={'Search for user, by name or email'} onChange={e => handleInput(e.target.value)}/>
  
      <div style={{display: "flex", flexDirection: "column"}}>
        {possibleUser.length ? possibleUser.map((user) => <Card style={{marginTop: 12, backgroundColor: user.id === selectedUser?.id  ? '#DEDEDE' : 'white'}} onClick={() => setUser(user)}>
          {user.id === selectedUser?.id ? `✅` : null } <strong>{" "}{user.firstname} {user.lastname}</strong><br/>
          <em style={{color: '#a7a7a7'}}>{user.email}</em>
        </Card>) : <Empty description={'No results, search user by email'}/>}
        {!!selectedUser && <Button onClick={handleClick} type={"primary"} size={"large"} style={{flex: 1, marginTop: '1em' }}>Attach {selectedUser?.firstname} to {name}</Button>}
      </div>
      
    </Modal>
  )
}

export default AttachUserModal