export default class AuthenticationManager {
  tokenExist: () => Promise<boolean> | boolean;
  getToken: () => Promise<string | null>;
  setToken: (token: string) => Promise<void> | void;
  clearToken: () => Promise<void> | void;

  constructor() {
    this.setToken = () => {
      throw new Error(
        "The setToken() methods isn't overrided, please provide your own !"
      );
    };

    this.getToken = () => {
      throw new Error(
        "The getToken() methods isn't overrided, please provide your own !"
      );
    };

    this.clearToken = () => {
      throw new Error(
        "The clearToken() methods isn't overrided, please provide your own !"
      );
    };

    this.tokenExist = () => {
      throw new Error(
        "The tokenExist() methods isn't overrided, please provide your own !"
      );
    };
  }
}

let authenticationManager: AuthenticationManager;

export { authenticationManager };
