import {Button, Row, Table, Tag} from "antd";
import {EditFilled} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Influencer from "../../chore/Influencer";
import {useNavigate} from "react-router-dom";
import {HitArray} from "../../hooks/useAlgolia";
import Statistics from "../../components/StatsCard";
import {format, formatDistance, getUnixTime, isBefore, parseISO} from "date-fns";
import APISearchBar from "../../components/APISearchInput";
import {CustomerType} from "../../chore/enums/CustomerType";
import Brand from "../../chore/Brand";

const { Column, ColumnGroup } = Table;
const InfluencersListPage = () => {
  
  const navigate = useNavigate()
  const [influencers, setInfluencers] = useState<Influencer[] | HitArray<Influencer> | undefined>([])
  const [isLoading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<{
    current: number | undefined,
    pageSize: number | undefined,
  }>({
    current: 1,
    pageSize: 10,
  })
  
  useEffect(() => {
    fetchUsers(400, 0).then()
  }, [pagination])
  
   const fetchUsers = async (limit: number, skip: number, query?: string) => {
     setLoading(true)
     let response = await Influencer.getAll(limit, skip, query);
     setInfluencers(response)
     setLoading(false)
     return response
   }
  
  const handleResults = (results: HitArray<Influencer>) => {
    if(!results.length) {
      fetchUsers(100, 0).then()
    } else {
      setInfluencers(results)
    }
  }
  
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>Influencers List</h1>
          <Button type={"primary"} href={'./influencers/new'}>Add an influencer</Button>
      </Row>
  
      <Statistics entity={"Influencers"}/>
      <div style={{marginTop: 20}}>
        <APISearchBar searchHandler={async (query?: string) => {
          return fetchUsers(100, 0, query)
        }} placeholder={'Search for influencers'}/>
        {/* @ts-ignore */}
        <Table loading={isLoading} dataSource={influencers} pagination={{...pagination, total: influencers?.length || 0}} onChange={(value) => setPagination({
          current: value.current,
          pageSize: value.pageSize,
        })}>
          <Column title="Name" dataIndex="name" key="name" render={(data: string, record: Influencer) => {
            return <>
              <img src={record.profilePicture?.fullSizeUrl || 'https://dummyimage.com/50x50/c9c9c9/000000.png&text=No Profile'} width={50} height={50} style={{borderRadius: 25}}/> {data}
            </>
          }} />
          <Column
            filters={[{text: 'Visible', value: true}, {text: 'Hidden', value: false}]}
            //@ts-ignore
            onFilter={(value: boolean, record: Brand) => {
              return record.visible === value
            }}
            title="Visibility"
            dataIndex="visible"
            key="visible"
            //@ts-ignore
            render={(data: boolean) => {
              return data ? <Tag color={'green'} title={'Visible'}>Visible</Tag> : <Tag color={'red'} title={'Hidden'}>Hidden</Tag>;
            }} />
          <Column title="Status"
                  filters={[{text: 'Access', value: CustomerType.access}, {text: 'Premium', value: CustomerType.premium}]}
            //@ts-ignore
                  onFilter={(value: CustomerType, record: Brand) => {
                    return record.status === value
                  }}
                  dataIndex="status" key="status" render={(data: CustomerType) => {
            return data === CustomerType.access ? <Tag color={'yellow'}>Access</Tag> : <Tag color={'black'} title={'Premium'}>Premium</Tag>;
          }} />
          <Column title="Created at" dataIndex="createdAt" key="createdAt"
                  sorter={(a: string, b: string) => isBefore(parseISO(a), parseISO(b)) ? -1 : 1}
                  render={
                    date => `${format(parseISO(date as string), 'dd/MM/yy')} (${formatDistance(
                      parseISO(date),
                      new Date(),
                      {addSuffix: true}
                    )})`} />
          <Column
            title="Last updated"
            dataIndex="updatedAt"
            key="updatedAt"
            sorter={(a: string, b: string) => getUnixTime(parseISO(a)) - getUnixTime(parseISO(b))}
            render={
              date => `${format(parseISO(date as string), 'dd/MM/yy')} (${formatDistance(
                parseISO(date),
                new Date(),
                {addSuffix: true}
              )})`}
          />
          <Column title={'Actions'} render={(_, record: Influencer) => {
            return <>
              <Button type={"primary"} href={'./influencers/' + record.hash} style={{borderRadius: 12}}><EditFilled/> Edit</Button>
            </>
          }}/>
        </Table>
      </div>
      
      
      
      
      
    </>
  )
}

export default InfluencersListPage
