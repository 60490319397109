import {Button, Card, Divider, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EntityCard from "../../components/profile/EntityCard";

import AddressCard from "../../components/profile/AddressCard";
import ProfileType from "../../chore/enums/ProfileType";
import PictureCard from "../../components/profile/PicturesCard";
import LinksCard from "../../components/profile/LinksCard";
import {LinkOutlined} from "@ant-design/icons";
import Brand from "../../chore/Brand";
import BrandProfileSection from "../../components/profile/BrandProfileSection";

import PaymentCard from "../../components/profile/PaymentSection";
import OpeningHoursSection from "../../components/profile/OpeningHoursSection";
import Testimonial from "../../chore/Testimonial";
import TestimonialProfileSection from "../../components/profile/TestimonialProfileSection";

const TestimonialDetailPage = () => {
  
  const [testimonial, setTestimonial] = useState<Testimonial>(new Testimonial())
  const {id} = useParams();
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!id) return
    fetch(id).then()
  }, [])
  
  
  const fetch = async (hash: string) => {
    if(hash !== 'new') {
      const testimonial = await Testimonial.get(hash);
      setTestimonial(testimonial)
    } else {
      const testimonial = new Testimonial();
      setTestimonial(testimonial)
    }
  }
  
  if(!testimonial) {
    return <Spin/>
  }
  
  
  return (
    <>
      <Row style={{flexDirection: 'row', flex: 1, justifyContent: "space-between"}}>
          <h1 style={{fontSize: '2em'}}>{testimonial.id ? `Testimonial #${testimonial.id}` : 'New testimonial' }</h1>
      </Row>
  
      <EntityCard entity={testimonial} name={testimonial.id ? `Testimonial #${testimonial.id.toString(10)}` : 'New testimonial'}/>
      <TestimonialProfileSection testimonial={testimonial} onUpdate={() => {}}/>
    </>
  )
}

export default TestimonialDetailPage