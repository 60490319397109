import Base from "./Base";
import Influencer from "./Influencer";
import {SocialNetworkType} from "./enums/SocialNetworkType";
import {client} from "./API/LocalClient";
import {ServerResponseType} from "./API/types";
import ProfileType from "./enums/ProfileType";
import Brand from "./Brand";
import Photographer from "./Photographers";


interface SocialNetworkData {
  followers: number;
  following: number;
  story_reach?: number;
}

export default class Link extends Base {
  
  /* Topology */
  
  prefix = 'link';
  socialNetwork!: SocialNetworkType;
  url!: string;
  handle?: string = '';
  data?: SocialNetworkData;
  brand?: Brand;
  influencer?: Influencer;
  photographer?: Photographer;
  
  brandHash?: Brand;
  influencerHash?: Influencer;
  photographerHash?: Photographer;
  errored: boolean = false
  
  static async getAll(params?: {entity?: ProfileType, entityId?: string, socialNetwork?: SocialNetworkType}) {
    let {data} = await client.request<ServerResponseType<Link[]>>(true, {
      url: '/v2/links',
      params: {...params}
    });
    return data.data.map(l => Object.assign(new Link(), l))
    
  }
  
  async get() {
  
    let {data} = await client.request<ServerResponseType<Link>>(true, {
      url: '/v2/links/' + this.hash,
    });
    return Object.assign(new Link(), data.data)
    
  }
  
  async create(entity: ProfileType, entityId: string) {
    let {data} = await client.request<ServerResponseType<Link[]>>(true, {
      url: '/v2/links',
      method: 'POST',
      data: {entity, entityId, link: this}
    });
    
  }
  
  async update() {
    let {data} = await client.request<ServerResponseType<Link>>(true, {
      url: '/v2/links/' + this.hash,
      method: 'PUT',
      data: this
    });
  }
  
  async delete() {
    let {status} = await client.request(true, {
      url: '/v2/links/' + this.hash,
      method: 'DELETE'
    });
    return status === 204
  }
  
  async retrieveData() {
    let {status} = await client.request(true, {
      url: '/v2/links/' + this.hash + '/metrics',
      method: 'PATCH'
    });
    return status === 200
  }
  
  
  
}
